import React, { useEffect, useRef, useState } from "react";
import "./MessagesContainer.scss";
import moment from "moment";
import Message from "../Message/Message";
import up from "../../../assets/images/arrow_up.svg";
import { IMessage, IMessageTranslationState } from "../../../models/MessageModel";
import { DATE_FORMAT, TOAST_STYLE } from "../../../common/constants";
import { getMessageParticipants, getUnreadMessages } from "../../../common/messageUtils";
import MessageInfoBar from "../MessageInfoBar/MessageInfoBar";
import MessageService from "../../../services/Message.service";
import { useAppStore } from "../../../contexts/app.context";
import ToastWrapper, {
  IToast,
  defaultToastObj,
} from "../../application/molecules/ToastWrapper/ToastWrapper";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { observer } from "mobx-react-lite";
import { sendMonitoringLogs } from "../../../common/utils";

export interface IMessagesContainerProps {
  subject: string;
  threadMessageList: IMessage[];
  showTransition: boolean;
}

const MessagesContainer = (props: IMessagesContainerProps) => {
  const { threadMessageList, subject, showTransition } = props;

  const msgRef = useRef<HTMLDivElement>(null);
  const msgParticipants = getMessageParticipants(threadMessageList);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isThreadActive, setThreadActive] = useState<boolean>();
  const [isCollapsable, setCollapsable] = useState<boolean>(true);

  const handleMessageList = () => {
    isThreadActive
      ? setMessages(threadMessageList)
      : setMessages([threadMessageList?.[0], ...threadMessageList?.slice?.(-3)]);
  };

  const [translationStates, setTranslationStates] = useState<{
    [key: string]: IMessageTranslationState;
  }>({});
  const messageService = new MessageService();
  const appStore = useAppStore();
  const [toastObj, setToastObj] = useState<IToast>(defaultToastObj);
  const LINK_PATTERN = /\[.*?\|(?:doc|nav|style):\/\/.*?\]/g;

  /**
   * Conversation threads can be collapsed or expaded only when message length > 6.
   * Initially thread will be in collapsed state if there is no unread message in the thread.
   */
  useEffect(() => {
    if (threadMessageList?.length < 6) {
      setCollapsable(false);
      setThreadActive(true);
    } else {
      setCollapsable(true);
    }
    handleMessageList();
    getUnreadMessages(threadMessageList).length > 0 && setThreadActive(true);
  }, [threadMessageList]);

  /**
   * If thread active, we show all the messages.
   * If thread inactive, we show only the first and the last three messages.
   */
  useEffect(() => {
    handleMessageList();
  }, [isThreadActive]);

  /**
   * Function to toggle the thread.
   */
  const toggleThreadActive = () => {
    try {
      setThreadActive(!isThreadActive);
      msgRef?.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log("toggleThreadActive ~ error:", error);
    }
  };

  /**
   * Function to check whether to show particular msg's information or not.
   * returns true, if adjacent messages are not in same direction or not in the same time.
   */
  const showMsgInfo = (index: number) => {
    try {
      const currentMsg = messages?.[index];
      const nextMsg = messages?.[index + 1];
      const currentMsgTime = moment(currentMsg?.timestamp).format(DATE_FORMAT);
      const nextMsgTime = moment(nextMsg?.timestamp).format(DATE_FORMAT);
      return currentMsg?.direction !== nextMsg?.direction || currentMsgTime !== nextMsgTime;
    } catch (error) {
      console.log("showMessageInfo ~ error:", error);
    }
  };

  /**
   * Function to handle Toast Message Close
   */
  const handleOnToastClose = () => setToastObj({ ...toastObj, showToast: false });

  /**
   * Function to extract links in a message and replace it with [[LINK_PLACEHOLDER]].
   */
  const extractAndReplaceLinks = (message: string) => {
    const links: string[] = [];
    const extractedMessage = message.replace(LINK_PATTERN, (match) => {
      links.push(match);
      return "[[LINK_PLACEHOLDER]]";
    });
    return { extractedMessage, links };
  };

  /**
   * Function to replace [[LINK_PLACEHOLDER]] with the actual links in the message.
   */
  const replacePlaceholdersWithLinks = (message: string, links: string[]) => {
    let index = 0;
    return message.replace(/\[\[LINK_PLACEHOLDER\]\]/g, () => {
      if (index < links.length) {
        return links[index++];
      }
      return "[[LINK_PLACEHOLDER]]";
    });
  };

  /**
   * Function to handle Translation Option
   * If the message is not translated, then we call the translation API to get the translated message.
   * If the message is translating, then we show the istranslating to true.
   * If there is any error in translation, then we show the error toast message.
   */
  const handleTranslation = (messageId: string, message: string) => {
    const state = translationStates[messageId] || {
      isTranslating: false,
      translatedMessage: "",
    };

    if (!state.isTranslating && !state.translatedMessage) {
      setTranslationStates((prevState) => ({
        ...prevState,
        [messageId]: { ...state, isTranslating: true },
      }));

      const { extractedMessage, links } = extractAndReplaceLinks(message);

      messageService
        .getTranslatedMessage(extractedMessage)
        .then((res) => {
          const replacedMessage = replacePlaceholdersWithLinks(res.data.message, links);
          setTranslationStates((prevState) => ({
            ...prevState,
            [messageId]: {
              ...state,
              translatedMessage: replacedMessage,
              isTranslating: false,
            },
          }));
        })
        .catch((err) => {
          console.log("handleTranslation ~ err", err);
          setTranslationStates((prevState) => ({
            ...prevState,
            [messageId]: { ...state, isTranslating: false, translatedMessage: "" },
          }));

          setToastObj({
            showToast: true,
            message: i18nInstance.t("TTM.followup.generic.error"),
            style: TOAST_STYLE.ERROR,
          });
        })
        .finally(() => {
          sendMonitoringLogs("MESSAGE_CONVERSATION_TRANSLATION_API_CALL_MADE");
        });
    }
  };

  useEffect(() => {
    setTranslationStates({});
  }, [appStore?.userLanguage]);

  return (
    <>
      <div className="msg-container">
        <div
          ref={msgRef}
          className={`msg-subject ${isCollapsable ? "cursor-pointer" : ""}`}
          onClick={isCollapsable ? toggleThreadActive : undefined}
        >
          <span className={"msg-subject-text"} title={subject}>
            {subject}
          </span>
          {isCollapsable && (
            <img className={`arrow ${isThreadActive ? "arrow-up" : "arrow-down"}`} src={up} />
          )}
        </div>
        {messages &&
          messages.length > 0 &&
          messages.map((message: IMessage, index: number) => (
            <React.Fragment key={`msg-${message.id}-${index}`}>
              <Message
                message={message}
                showTransition={showTransition}
                showMsgInfo={showMsgInfo(index)}
                translationStates={translationStates[message.id]}
                handleTranslation={handleTranslation}
              />
              {!isThreadActive && index === messages.length - 4 && (
                <MessageInfoBar
                  count={threadMessageList.length - 4}
                  participants={msgParticipants}
                  handleOnClick={toggleThreadActive}
                />
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="error-msg-toast">
        <ToastWrapper onClose={handleOnToastClose} toastProps={toastObj} />
      </div>
    </>
  );
};

export default observer(MessagesContainer);
