import React from "react";
import "./FormCheckbox.scss";
import Form from "@trimbleinc/modus-react-bootstrap/esm/Form";
export interface IFormCheckbox {
  id: string;
  label: string;
  isChecked?: boolean;
  onChange?: (flag: boolean) => void;
  title?: string;
  isDisabled?: boolean;
}
const FormCheckbox = (props: IFormCheckbox) => {
  const { title, isDisabled, label, isChecked, onChange } = props;

  const handleOnChange = () => {
    onChange?.(!isChecked);
  };

  return (
    <Form.Check
      custom
      type="checkbox"
      id={`custom-checkbox-${props.id}`}
      label={label}
      className="checkbox"
      checked={isChecked}
      onChange={handleOnChange}
      title={title ? title : label}
      disabled={isDisabled}
    />
  );
};

export default FormCheckbox;
