import React from "react";
import "./TracePropertyDetails.scss";
import { ITraceProperty } from "../../../../../models/TraceModel";
import DocumentNameFormatter from "../../../../application/molecules/DocumentNameFormatter/DocumentNameFormatter";
import { DOCUMENT_PATTERN } from "../../../../../common/constants";
import { getFollowupURL } from "../../../../../common/serviceUtils";
export interface ITracePropertyDetails {
  traceProperties: ITraceProperty[];
  terminalId?: string;
}

const TracePropertyDetails = (props: ITracePropertyDetails) => {
  return (
    <>
      {props?.traceProperties?.map(
        (property: ITraceProperty) =>
          property && (
            <div key={property.propertyKey} className="trace-property-details">
              <span className="trace-property-key">{`${property?.propertyKey} : `}</span>
              <DocumentNameFormatter
                text={`${property?.propertyValue}`}
                type={DOCUMENT_PATTERN}
                isDownload={true}
                linkPrefix={getFollowupURL(`files/terminals/${props?.terminalId}/`)}
              />
            </div>
          ),
      )}
    </>
  );
};

export default TracePropertyDetails;
