import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ApplicationProvider from "@ttl/shared-react-library/src/providers/ApplicationProvider";
import AppLayout from "../components/application/pages/AppLayout/AppLayout";
import ErrorBoundary from "../components/molecules/ErrorBoundary/ErrorBoundary";
import { getAppConfig } from "../common/utils";
import { AppProvider } from "../contexts/app.context";
function Shell() {
  const title = getAppConfig().appTitle;
  useEffect(() => {
    document.title = title;
    const metaDesc = document.querySelector("meta[name='description']");
    metaDesc?.setAttribute("content", title);
  }, [title]);
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ApplicationProvider
          additionalClassName={"trimblemaps-map"}
          config={getAppConfig().identity}
        >
          <AppProvider>
            <AppLayout />
          </AppProvider>
        </ApplicationProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default Shell;
