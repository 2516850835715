import moment from "moment";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { IMessage, IMessageStatus } from "../models/MessageModel";
import { MESSAGE_DIRECTION, MESSAGE_STATUS } from "./constants";
import { isElementInViewport } from "./utils";

export const NEW_MESSAGE_THREAD = "new-msg-thread";
export const MESSAGE_READ = {
  value: "READ",
  index: MESSAGE_STATUS.READ,
};

export const getMessageDuration = (time: number) => {
  let formattedDuration = "";
  try {
    const duration = moment.duration(moment().diff(moment(time)));
    const [years, months, days, hours, minutes, seconds] = [
      duration.years(),
      duration.months(),
      duration.days(),
      duration.hours(),
      duration.minutes(),
      duration.seconds(),
    ];
    if (years >= 1 || months >= 1 || days >= 1) {
      formattedDuration = moment(time).format("ddd, D MMM");
    } else if (years === 0 && months === 0 && days === 0 && hours > 0) {
      formattedDuration = `${hours} ${i18nInstance.t("TTM.followup.duration.hour")} `;
    } else if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes > 0) {
      formattedDuration = `${minutes} ${i18nInstance.t("TTM.followup.duration.minute")} `;
    } else if (
      years === 0 &&
      months === 0 &&
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds >= 0
    ) {
      formattedDuration = `${i18nInstance.t("TTM.followup.duration.now")}`;
    }
  } catch (error) {
    formattedDuration = "";
    console.log("Error getMessageDuration", error);
  }
  return formattedDuration;
};

export const getMessageParticipants = (messages: IMessage[]) => {
  try {
    const participants: string[] = [];
    messages.forEach((msg: IMessage) => {
      if (msg.sender?.name && !participants.includes(msg.sender.name)) {
        participants.push(msg.sender.name);
      }
    });
    return participants;
  } catch (error) {
    console.log("getMessageParticipants ~ error:", error);
  }
};

export const isMessageUnread = (message: IMessage) => {
  try {
    return (
      message.direction === MESSAGE_DIRECTION.FROM_DEVICE &&
      (message.status?.index === MESSAGE_STATUS.NEW ||
        message.status?.index == MESSAGE_STATUS.MARK_UNREAD)
    );
  } catch (error) {
    console.log("isMessageUnread ~ error:", error);
  }
};

export const getUnreadMessages = (messageList: IMessage[]) => {
  let unreadMessages: IMessage[] = [];
  try {
    unreadMessages = messageList.filter((message: IMessage) => isMessageUnread(message));
  } catch (error) {
    unreadMessages = [];
    console.log("getUnreadMessages ~ error:", error);
  }
  return unreadMessages;
};

export const getUnreadIdsFromViewport = (
  messageList: IMessage[],
  offset?: { offsetTop?: number; offsetBottom?: number; offsetLeft?: number; offsetRight?: number },
) => {
  let unreadMsgIds: string[] = [];
  try {
    getUnreadMessages(messageList).forEach((message: IMessage) => {
      const element = document.getElementById(`message-${message.id}`);
      const isVisible = element && isElementInViewport(element, { offsetTop: offset?.offsetTop });
      isVisible && unreadMsgIds.push(message.id);
    });
  } catch (error) {
    unreadMsgIds = [];
    console.log("getUnreadIdsFromViewport ~ error:", error);
  }
  return unreadMsgIds;
};

export const isMessageSentNow = (timeStamp: number) => {
  let isSentNow = false;
  try {
    const seconds = moment.duration(moment().diff(moment(timeStamp))).seconds();
    isSentNow = seconds >= 0 && seconds <= 3;
  } catch (error) {
    console.log("isMessageSentNow ~ error:", error);
  }
  return isSentNow;
};
