import React, { ReactElement } from "react";
import phone from "../../../assets/images/Icon_material_call.svg";
import "./TitleView.scss";
import { formatVehicleType, sendMonitoringLogs } from "../../../common/utils";
import { IStatus, ITrailer } from "../../../models/VehicleModel";
import FleetIcon from "../FleetIcon/FleetIcon";
import back from "../../../assets/images/arrow_back.svg";
import close from "../../../assets/images/close.svg";
import { ReactComponent as Chat } from "../../../../public/assets/images/message_centre_icon.svg";
import { ReactComponent as HistoryIcon } from "../../../../public/assets/images/history_icon.svg";
import BadgeIcon from "../BadgeIcon/BadgeIcon";
import { useNavigate } from "react-router-dom";
import { DATE_DISPLAY_FORMAT, PATH } from "../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import arrow from "../../../assets/images/arrow_forward.svg";
import moment from "moment";
export interface ITitleProps {
  id?: string;
  type?: string;
  icon?: string;
  title?: string;
  phone?: string;
  status?: IStatus;
  trailer?: ITrailer;
  isMobile?: boolean;
  showHistoryIcon?: boolean;
  showCloseIcon?: boolean;
  onClose?: () => void;
  unread?: number;
  showMessageIcon?: boolean;
  showLink?: boolean;
  onTitleClick?: () => void;
}

const TitleView = (props: ITitleProps) => {
  const vehicleType = formatVehicleType(props.type);
  const navigate = useNavigate();

  const navigateToMessageCenter = () => {
    sendMonitoringLogs("VEHICLE_DETAILS_NAVIGATE_MESSAGE_CENTER");
    navigate(`${PATH.MESSAGES}/unit/${props.id}`);
  };

  const navigateToHistory = () => {
    sendMonitoringLogs("VEHICLE_DETAILS_NAVIGATE_HISTORY");
    navigate(`${PATH.TRACES}/${props.id}/${moment().format(DATE_DISPLAY_FORMAT)}`);
  };

  return (
    <div className={`vehicles-title-card ${props?.isMobile ? "vehicles-title-card-mobile" : ""}`}>
      <div className="vehicle-title">
        {props?.isMobile ? (
          <img src={back} className="back-icon mr-3" onClick={() => props?.onClose?.()} />
        ) : null}
        <div className="title-block" onClick={() => props?.onTitleClick?.()}>
          {props.type && (
            <FleetIcon type={props.type} trailer={props.trailer} status={props?.status} />
          )}
          <span className="truncate-text ml-0.5">
            {vehicleType}
            {props?.title ? (
              <span className="ml-1 title" title={props?.title}>
                {vehicleType ? ": " : ""}
                {props?.title}
              </span>
            ) : (
              <></>
            )}
          </span>
          {props?.showLink ? <div>{<img src={arrow} className="arrow-forward" />}</div> : null}
        </div>
      </div>
      <div className="vehicle-title">
        {props?.showHistoryIcon && (
          <HistoryIcon
            title={i18nInstance.t("TTM.followup.traces.tracesAudit")}
            className="vehicle-title-icon history-icon"
            onClick={navigateToHistory}
          />
        )}
        {props?.showMessageIcon ? (
          <BadgeIcon
            badgeTitle={i18nInstance.t("TTM.followup.messages.messageCentre")}
            count={props.unread}
            iconSrc={<Chat className="vehicle-title-icon message-icon" />}
            handleClick={navigateToMessageCenter}
          />
        ) : null}
        {props?.phone ? (
          <a href={`tel:${props.phone}`}>
            <img src={phone} className="mr-1 phone-icon" />
          </a>
        ) : null}
        {!props?.isMobile && props?.showCloseIcon ? (
          <img
            src={close}
            className="close-icon btn btn-md btn-icon-only"
            onClick={() => props?.onClose?.()}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TitleView;
