import React from "react";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import { useOutletContext } from "react-router-dom";
import { IVehicleData } from "../../../models/VehicleModel";
import DetailsView from "../../organisms/DetailsView/DetailsView";
import { IRoute, ITask } from "../../../models/VehicleDetailsModel";
import { ISearchResult } from "../../organisms/SearchView/SearchViewModel";

export interface IDetails {
  handleDetailsClose: () => void;
  handleSelectedRoute: (route: IRoute | null | undefined) => void;
  selectedTask?: ITask | null;
  selectedRoute?: IRoute | null;
  selectedVehicle: IVehicleData;
  lastUpdatedTime?: number;
  searchedLocation?: ISearchResult;
}

const Details = () => {
  const detailsContext = useOutletContext<IDetails>();
  return (
    <DetailsView
      onClose={detailsContext.handleDetailsClose}
      setSelectedRoute={detailsContext.handleSelectedRoute}
      selectedTask={detailsContext?.selectedTask}
      selectedRoute={detailsContext?.selectedRoute}
      selectedVehicle={detailsContext.selectedVehicle}
      lastUpdatedTime={detailsContext?.lastUpdatedTime}
      searchedLocation={detailsContext?.searchedLocation}
    />
  );
};

export default withAuth(Details);
