import React from "react";
import "./ConversationHeader.scss";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../../assets/images/arrow_back.svg";
import useMobileDetect from "../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { PATH } from "../../../common/constants";
import Button from "@trimbleinc/modus-react-bootstrap/esm/Button";
import { ReactComponent as NewThreadIcon } from "../../../assets/images/add.svg";
import { NEW_MESSAGE_THREAD } from "../../../common/messageUtils";
import { IVehicle } from "../../../models/VehicleModel";
import TitleView from "../TitleView/TitleView";
import { sendMonitoringLogs } from "../../../common/utils";
export interface IConversationHeader {
  isNewThreadBtnDisabled: boolean;
  vehicle?: IVehicle;
}

const ConversationHeader = (props: IConversationHeader) => {
  const { isNewThreadBtnDisabled } = props;
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const params = useParams();

  const handleBackClick = () => {
    navigate(PATH.MESSAGES);
  };

  const handleOnNewThreadClick = () => {
    sendMonitoringLogs("MESSAGE_CONVERSATION_NEW_THREAD");
    navigate(`${PATH.MESSAGES}/unit/${params?.unitId}/#${NEW_MESSAGE_THREAD}`);
  };
  const handleClickOnVehicleName = () => {
    sendMonitoringLogs("MESSAGE_CONVERSATION_NAVIGATE_VEHICLE_DETAILS");
    navigate(`${PATH.VEHICLES}/${params?.unitId}`);
  };
  return (
    <div className="conversation-header">
      {isMobile && (
        <img
          className="msg-back-btn"
          title={i18nInstance.t("TTM.followup.backToVehiclesMessageView")}
          onClick={handleBackClick}
          src={back}
        />
      )}
      {/* Need to include the vehicle name in the header. */}
      <div className="conversation-vehicle ">
        <TitleView
          type={props?.vehicle?.type}
          title={props?.vehicle?.name}
          phone={props?.vehicle?.phone}
          showLink={true}
          onTitleClick={handleClickOnVehicleName}
        />
      </div>
      <Button
        size="sm"
        variant={"outline-primary"}
        disabled={isNewThreadBtnDisabled}
        className="msg-btn mx-2"
        onClick={handleOnNewThreadClick}
      >
        <NewThreadIcon />
        {!isMobile && <span>{i18nInstance.t("TTM.followup.messages.newThread")}</span>}
      </Button>
    </div>
  );
};

export default ConversationHeader;
