import React from "react";
import { MESSAGE_STATUS } from "../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { IMessageStatus } from "../../../models/MessageModel";
import messageSent from "../../../assets/images/message/msg_sent.svg";
import messageRead from "../../../assets/images/message/msg_read.svg";
import messageReceived from "../../../assets/images/message/msg_received.svg";

export interface IMessageStatusIcon {
  status: IMessageStatus;
}

const MessageStatusIcon = (props: IMessageStatusIcon) => {
  const { status } = props;
  return (
    <>
      {status.index === MESSAGE_STATUS.NEW && (
        <img src={messageSent} title={i18nInstance.t("TTM.followup.messages.new")} />
      )}
      {status.index === MESSAGE_STATUS.RECEIVED && (
        <img src={messageReceived} title={i18nInstance.t("TTM.followup.messages.received")} />
      )}
      {(status.index === MESSAGE_STATUS.READ || status.index === MESSAGE_STATUS.ANSWERED) && (
        <img src={messageRead} title={i18nInstance.t("TTM.followup.messages.read")} />
      )}
    </>
  );
};

export default MessageStatusIcon;
