import React from "react";
import "./IconWithLabel.scss";

export interface IProps {
  icon: React.ReactNode;
  label: string;
}

const IconWithLabel = (props: IProps) => {
  const { icon, label } = props;

  return (
    <div className="icon-with-label-container">
      {icon && <div className="iwl-icon">{icon}</div>}
      {label && <div className="iwl-text">{label}</div>}
    </div>
  );
};

export default IconWithLabel;
