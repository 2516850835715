import React from "react";
import "./PanelContainer.scss";

export interface IPanelContainer {
  header: React.ReactElement;
  content: React.ReactElement;
  footer: React.ReactElement;
}

const PanelContainer = (props: IPanelContainer) => {
  const { header, content, footer } = props;
  return (
    <div className="panel-container">
      <div className="header">{header}</div>
      <div className="content">{content}</div>
      <div className="footer">{footer}</div>
    </div>
  );
};

export default PanelContainer;
