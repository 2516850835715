import React from "react";
import Form from "@trimbleinc/modus-react-bootstrap/Form";
import "./FormInput.scss";

export interface IFormInput {
  id: string;
  placeholder: string;
  onFocus?: () => void;
  inputValue?: string;
  autoFocus?: boolean;
  maxLength?: number;
  onChange?: (param: string) => void;
}

const FormInput = (props: IFormInput) => {
  const { id, placeholder, inputValue, autoFocus, maxLength, onFocus, onChange } = props;

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e?.target?.value);
  };

  return (
    <Form.Control
      id={id}
      className="form-input"
      as="input"
      type="text"
      placeholder={placeholder}
      onFocus={onFocus}
      autoFocus={autoFocus}
      value={inputValue}
      onChange={handleOnChange}
      maxLength={maxLength}
    ></Form.Control>
  );
};

export default FormInput;
