import { IMenuItem, menuItems } from "./menuList";
import { ExternalModulesConfig, IModules, getAppConfig } from "./utils";

export const updateExternalModuleLink = (menu: IMenuItem) => {
  const externalMenuConfig = getAppConfig()?.external_modules;
  if (menu?.isExternalModule && Object.keys(externalMenuConfig).includes(menu.name.toLowerCase())) {
    menu.link = externalMenuConfig?.[menu.name.toLowerCase() as keyof ExternalModulesConfig];
  }
  return menu;
};

export const filterByFlags = (menu: IMenuItem) => {
  try {
    const modulesConfig = getAppConfig()?.modules;
    return modulesConfig?.[menu.name.toLowerCase() as keyof IModules] === false ? null : menu;
  } catch (error) {
    console.log("filterByFlags ~ error:", error);
  }
};

export const filterMenusByPermission = (permissions: string[]) => {
  try {
    return menuItems
      .filter((menu: IMenuItem) => permissions.includes(menu.permission))
      .filter(filterByFlags)
      .map(updateExternalModuleLink);
  } catch (error) {
    console.log("filterMenusByPermission ~ error:", error);
  }
};

export const updateMenuBadgeCount = (menus: IMenuItem[], menuName: string, unreadCount: number) => {
  try {
    const updatedMenus = [...menus];
    updatedMenus?.length > 0 &&
      updatedMenus?.forEach((menu) => menu.name === menuName && (menu.badgeCount = unreadCount));
    return updatedMenus;
  } catch (error) {
    console.log("updateMenuBadgeCount ~ error:", error);
  }
};

export const updateMenuActiveStatus = (menus: IMenuItem[], pathName: string) => {
  try {
    const updatedMenus = [...menus];
    if (updatedMenus?.length > 0) {
      updatedMenus.forEach((menu: IMenuItem) => {
        menu.isActive = pathName.indexOf(menu.link) !== -1 || menu.relatedPaths?.includes(pathName);
      });
    }
    return updatedMenus;
  } catch (error) {
    console.log("updateMenuActiveStatus ~ error", error);
  }
};
