import React from "react";
import "./DatePicker.scss";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "@trimbleinc/modus-react-bootstrap/Form";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export interface IDatePickerProps {
  label?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  showTimeSelect?: boolean;
  showClearBtn?: boolean;
  showTodayBtn?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  selectedDate?: Date | null;
  onDateSelect?: (date: Date | null) => void;
  children: React.ReactNode;
}

const DatePicker = (props: IDatePickerProps) => {
  const {
    label,
    minDate,
    maxDate,
    showTimeSelect,
    showClearBtn,
    showTodayBtn,
    timeFormat,
    timeIntervals,
    selectedDate,
    onDateSelect,
  } = props;
  return (
    <Form.Group>
      {label && <div className="multiSelect-label">{label}</div>}
      <div className="datepicker">
        <ReactDatePicker
          selected={selectedDate}
          showTimeSelect={showTimeSelect}
          dateFormat={i18nInstance.t("TTM.datePickerFormat")}
          minDate={minDate}
          maxDate={maxDate}
          todayButton={showTodayBtn && i18nInstance.t("TTM.followup.datePicker.today")}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          customInput={<div>{props.children}</div>}
          onChange={(date) => onDateSelect?.(date)}
          value={""}
        >
          {showClearBtn && (
            <div className="clear" onClick={() => onDateSelect?.(null)}>
              {i18nInstance.t("TTM.followup.clear")}
            </div>
          )}
        </ReactDatePicker>
      </div>
    </Form.Group>
  );
};

export default DatePicker;
