import React from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { getCrewDriver } from "../../../common/utils";
import { ICrew } from "../../../models/VehicleModel";
import TachoStatusIcon from "../TachoStatusIcon/TachoStatusIcon";
import { COLOR_VALUES } from "../../../common/constants";
export interface ITachoStatusProps {
  data: ICrew[];
}
const defaultProps: ITachoStatusProps = {
  data: [],
};
const TachoStatus = (props: ITachoStatusProps) => {
  const crewDriver = getCrewDriver(props.data);
  return crewDriver ? (
    <div className="d-flex flex-row tachoStatus__wrapper">
      <span title={i18nInstance.t("TTM.followup.tachoStatus")} className="tachoStatus_icon">
        <TachoStatusIcon type={crewDriver?.drivingType} color={COLOR_VALUES.BLUE_100} />
      </span>
      <div className="mt-1">
        {i18nInstance.t(`TTM.followup.tachoStatus.${crewDriver.drivingType?.toLowerCase()}`)}
      </div>
    </div>
  ) : (
    <></>
  );
};
TachoStatus.defaultProps = defaultProps;
export default TachoStatus;
