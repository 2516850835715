import React from "react";
import "./TwoColumnTemplate.scss";

export interface IColumn {
  component: React.ReactElement;
  colSpan: number;
}

export interface IProps {
  leftPane: IColumn;
  rightPane: IColumn;
}

const TwoColumnTemplate = (props: IProps) => {
  const { leftPane, rightPane } = props;
  return (
    <div className="two-col-container">
      <div className={`col-${leftPane.colSpan}`}>{leftPane.component}</div>
      <div className={`col-${rightPane.colSpan}`}>{rightPane.component}</div>
    </div>
  );
};

export default TwoColumnTemplate;
