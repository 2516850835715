import React from "react";
import Moment from "react-moment";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { DATE_TIME_FORMAT } from "../../../common/constants";
import { AppService } from "../../../services/app.services";
export interface IDateTimeProps {
  dateTime?: number;
  displayFormat?: string;
}
const defaultProps: IDateTimeProps = {
  displayFormat: DATE_TIME_FORMAT,
};
const DateTime = (props: IDateTimeProps) => {
  const { dateTime } = props;

  const dateTimeFormat = props.displayFormat
    ? props.displayFormat
    : i18nInstance.t("TTM.dateTimeFormat");

  return dateTime ? (
    <Moment
      local
      format={dateTimeFormat}
      className="date-time truncate-text"
      withTitle
      titleFormat={dateTimeFormat}
      tz={AppService.timezone}
    >
      {dateTime}
    </Moment>
  ) : (
    <></>
  );
};
DateTime.defaultProps = defaultProps;
export default React.memo(DateTime);
