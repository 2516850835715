import React from "react";
import "./RowList.scss";
import IconWithLabel from "../../molecules/IconWithLabel/IconWithLabel";

export interface IData {
  id: string;
  value: string;
  icon?: React.ReactNode;
}

export interface IProps {
  label?: string;
  data: IData[];
  showIcon?: boolean;
  containerCls?: string;
}

const defaultProps: IProps = {
  data: [],
  showIcon: false,
  containerCls: "",
};

const RowList = (props: IProps) => {
  const { data, showIcon, containerCls } = props;
  return (
    <div className={`row-list-container ${containerCls}`}>
      {data.length > 0 &&
        data.map((summary: IData) => {
          return (
            <React.Fragment key={summary.id}>
              <IconWithLabel icon={showIcon && summary.icon} label={summary.value} />
            </React.Fragment>
          );
        })}
    </div>
  );
};

RowList.defaultProps = defaultProps;
export default RowList;
