import React, { useEffect, useRef, useState } from "react";
import "./VehicleSummary.scss";
import Card from "../../../../molecules/Card/Card";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { metreToKilometre } from "../../../../../common/utils";
import { MEASUREMENT_UNITS } from "../../../../../common/constants";
import { AxiosResponse } from "axios";
import TraceService from "../../../../../services/Trace.service";

export interface IVehicleSummary {
  vehicleId: string;
  startTime: string;
  endTime: string;
}

interface IVehicleSummaryData {
  mileage?: number;
  fuel?: number;
}

const VehicleSummary = (props: IVehicleSummary) => {
  const { vehicleId, startTime, endTime } = props;
  const controller = useRef<AbortController>();
  const traceService = new TraceService();
  const [vehicleSummary, setVehicleSummary] = useState<IVehicleSummaryData>();

  const fetchVehicleSummary = () => {
    setVehicleSummary(undefined);
    traceService
      .getVehicleSummary(vehicleId, startTime, endTime, controller.current)
      .then((res: AxiosResponse) => {
        res.data && setVehicleSummary(res.data);
      })
      .catch((error) => {
        console.log("fetchVehicleSummary ~ error:", error);
      });
  };

  useEffect(() => {
    controller.current = new AbortController();
    if (vehicleId && startTime && endTime) {
      fetchVehicleSummary();
    }
    return () => controller.current?.abort();
  }, [vehicleId, startTime, endTime]);

  return (
    <div className="vehicle-summary-container">
      {vehicleSummary?.mileage != null && (
        <Card
          title={i18nInstance.t("TTM.followup.vehicle.distanceCovered")}
          value={`${metreToKilometre(vehicleSummary?.mileage)} ${MEASUREMENT_UNITS.DISTANCE}`}
        />
      )}
      {vehicleSummary?.fuel != null && (
        <Card
          title={i18nInstance.t("TTM.followup.vehicle.fuelConsumption")}
          value={`${vehicleSummary?.fuel} ${MEASUREMENT_UNITS.LITERS}`}
        />
      )}
    </div>
  );
};

export default VehicleSummary;
