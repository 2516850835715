import React from "react";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import Form from "@trimbleinc/modus-react-bootstrap/esm/Form";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import FormGroup from "@trimbleinc/modus-react-bootstrap/esm/FormGroup";
import basic from "../../../../assets/images/map_style_basic.svg";
import satellite from "../../../../assets/images/map_style_satellite.svg";
import transportation from "../../../../assets/images/map_style_transportation.svg";
import { IMapLayerStyleOption } from "../MapLayerStyleContainer/MapLayerStyleContainer";

export interface IMapStyle {
  selectedMapStyle: string;
  handleMapStyleChange: (style: IMapLayerStyleOption) => void;
}

export const MAP_STYLES: IMapLayerStyleOption[] = [
  {
    img: basic,
    key: "TTM.followup.map.style.basic",
    type: TrimbleMaps.Common.Style.BASIC,
  },
  {
    img: satellite,
    key: "TTM.followup.map.style.satellite",
    type: TrimbleMaps.Common.Style.SATELLITE,
    options: {
      satelliteProvider: TrimbleMaps.Common.SatelliteProvider.SAT2,
    },
  },
  {
    img: transportation,
    key: "TTM.followup.map.style.transportation",
    type: TrimbleMaps.Common.Style.TRANSPORTATION,
  },
];

const MapStyle = (props: IMapStyle) => {
  return (
    <>
      <span className="map-style-layer-title">{i18nInstance.t("TTM.followup.map.mapStyles")}</span>
      <FormGroup className="m-0">
        {MAP_STYLES.map((mapStyle: IMapLayerStyleOption) => {
          return (
            <div
              className="option-container"
              key={mapStyle.key}
              title={i18nInstance.t(mapStyle.key)}
              onClick={() => {
                if (mapStyle.type !== props.selectedMapStyle) {
                  props.handleMapStyleChange(mapStyle);
                }
              }}
            >
              <Form.Check
                type="radio"
                id={mapStyle.key}
                checked={props.selectedMapStyle === mapStyle.type}
                readOnly
              />
              <img src={mapStyle.img} />
              <span className="option-label">{i18nInstance.t(mapStyle.key)}</span>
            </div>
          );
        })}
      </FormGroup>
    </>
  );
};

export default withAuth(MapStyle);
