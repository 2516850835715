import React, { useState, useEffect } from "react";
import Form from "@trimbleinc/modus-react-bootstrap/Form";
import WithAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import "./TextInput.scss";
export interface IProps extends React.HtmlHTMLAttributes<HTMLElement> {
  handleInputChange?: (query: string) => void;
  handleClick?: (display: boolean) => void;
  handleClearText?: (e: React.MouseEvent<any>) => void;
  inputValue?: string;
  autoFocus?: boolean;
  maxLength?: number;
}
const TextInput = (props: IProps) => {
  const { inputValue, autoFocus } = props;
  const [searchText, setSearchText] = useState(inputValue);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || "";
    props.handleInputChange?.(value);
    setSearchText(value);
  };
  const clearText = (e: React.MouseEvent<any>) => {
    setSearchText("");
    props.handleClearText?.(e);
  };
  useEffect(() => {
    setSearchText(inputValue);
  }, [inputValue]);
  return (
    <div className="input-with-icon-left">
      <Form.Control
        as="input"
        type="text"
        maxLength={props?.maxLength}
        placeholder={props?.placeholder || i18nInstance.t("TTM.followup.search.placeholderText")}
        onChange={changeHandler}
        value={searchText}
        onClick={() => props.handleClick?.(true)}
        onFocus={() => props.handleClick?.(true)}
        title={
          searchText || props?.placeholder || i18nInstance.t("TTM.followup.search.placeholderText")
        }
        autoFocus={autoFocus}
      ></Form.Control>
      <div className="input-icon">
        <i className="modus-icon material-icons">search</i>
      </div>
      {searchText && (
        <div
          className="input-icon clear-icon cursor-pointer"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => clearText(e)}
          title={i18nInstance.t("TTM.followup.clear")}
        >
          <i className="modus-icon material-icons mw-25">clear</i>
        </div>
      )}
    </div>
  );
};
export default WithAuth(TextInput);
