import React from "react";
import { getBriefAddress, getFormattedAddress } from "../../../../../common/utils";
import { IAddress } from "../../../../../models/VehicleModel";
import Dash from "../../../../atoms/Dash/Dash";
export interface IAddressProps {
  data: IAddress;
  lineClamp?: number;
  isBrief?: boolean;
}
const defaultProps: IAddressProps = {
  data: {} as IAddress,
  lineClamp: 2,
  isBrief: false,
};
const Address = (props: IAddressProps) => {
  const { data, lineClamp, isBrief } = props;
  const addressVal = isBrief ? getBriefAddress(data) : getFormattedAddress(data);
  return props.data ? (
    <div
      className="d-flex flex-wrap truncate-lines address"
      style={{ WebkitLineClamp: isBrief ? 1 : lineClamp }}
      title={addressVal}
    >
      {addressVal}
    </div>
  ) : (
    <Dash />
  );
};
Address.defaultProps = defaultProps;
export default Address;
