import React, { createContext, useContext, useMemo } from "react";
import { TraceStore } from "../stores/traceStore/trace.store";

const TraceContext = createContext<TraceStore | null>(null);
export const useTraceStore = () => useContext(TraceContext);

export const TraceProvider = ({ children }: any) => {
  const traceStore = useMemo(() => new TraceStore(), []);
  return <TraceContext.Provider value={traceStore}>{children}</TraceContext.Provider>;
};
