import React from "react";
import "./HighlightText.scss";

export interface IHighLightTextProps {
  searchText?: string;
  originalText: string;
  containerCls?: string;
}

const getHighlightedText = (text: string, highlight: string) => {
  const splitText = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {splitText?.map((subText: string, index: number) => {
        const uniqueKey = `${subText}-${index}`
        const isMatch = subText.toLowerCase() === highlight.toLowerCase();
        return (
          <span key={uniqueKey} className={`${isMatch ? "highlight" : ""}`}>
            {subText}
          </span>
        );
      })}
    </span>
  );
};

const HighlightText = (props: IHighLightTextProps) => {
  const { searchText, originalText, containerCls = "" } = props;
  return( 
    <span className={`text truncate-text pl-1 ${containerCls}`} title={originalText}>
      {searchText?.length ? getHighlightedText(originalText, searchText): originalText}
    </span>
  ) 
}
export default HighlightText;