import React from "react";
import "./DrivingProgressBar.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TachoStatusIcon from "../TachoStatusIcon/TachoStatusIcon";
import { getFormattedHoursAndMinutes } from "../../../common/utils";
import { IDrivingTimeDuration } from "../../../models/VehicleModel";
import SkeletonComponent from "../../atoms/Skeleton/SkeletonComponent";
import { COLOR_VALUES, DRIVING_TYPES } from "../../../common/constants";

export interface IDrivingProgressBar {
  title: string;
  fillColor: string;
  showMaxLabel?: boolean;
  isLoading?: boolean;
  data?: IDrivingTimeDuration;
}

const DrivingProgressBar = (props: IDrivingProgressBar) => {
  const { duration, maxDuration, restingCoupure } = {
    ...props.data,
  };
  const calculateProgress = (partial?: number, total?: number) =>
    partial && total ? (100 * partial) / total : null;

  const checkViolation = (duration: number, maxDuration: number) => duration > maxDuration;

  const formattedDuration = duration && getFormattedHoursAndMinutes(duration);
  const formattedMaxDuration = maxDuration && getFormattedHoursAndMinutes(maxDuration);
  const remainingDuration = duration && maxDuration ? maxDuration - duration : undefined;
  const formattedRemainingValue =
    remainingDuration && getFormattedHoursAndMinutes(remainingDuration);
  const isViolated = duration && maxDuration && checkViolation(duration, maxDuration);

  return (
    <div className="progress-container">
      {/* Row for title and maximumValue */}
      <div className="paper-title">
        {props?.isLoading ? (
          <SkeletonComponent height={14} width={160} />
        ) : (
          <>
            <span className="truncate-text" title={props.title}>
              {props.title}
            </span>
            {formattedMaxDuration ? (
              <span>{`${
                props?.showMaxLabel ? i18nInstance.t("TTM.followup.max") : ""
              } ${formattedMaxDuration}`}</span>
            ) : null}
          </>
        )}
      </div>

      {/* Progress Bar */}
      {props?.isLoading ? (
        <SkeletonComponent height={20} width={280} />
      ) : (
        <div className={`progress-bar-container ${isViolated && "progress-bar-warning"}`}>
          <div
            className={`progress-bar-fill`}
            style={{
              width: `${calculateProgress(duration, maxDuration)}%`,
              backgroundColor: isViolated ? COLOR_VALUES.RED_100 : props.fillColor,
            }}
          >
            <span className="progress-content progress-content-start">{formattedDuration}</span>
            {restingCoupure ? (
              <div
                className={`progress-bar-fill progress-bar-fill-rest`}
                title={i18nInstance.t("TTM.followup.tachoStatus.resting")}
                style={{
                  width: `${calculateProgress(restingCoupure, duration)}%`,
                }}
              >
                <span className="progress-bar-icon">
                  <TachoStatusIcon
                    type={DRIVING_TYPES.RESTING}
                    color={COLOR_VALUES.WHITE}
                    dimensions={{ width: "22", height: "22" }}
                  />
                </span>
              </div>
            ) : null}
          </div>
          {formattedRemainingValue ? (
            <span className="progress-content progress-content-end">{formattedRemainingValue}</span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DrivingProgressBar;
