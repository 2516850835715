import React, { useEffect, useRef, useState } from "react";
import "./FormTextarea.scss";
import Form from "@trimbleinc/modus-react-bootstrap/Form";
export interface IFormTextarea {
  id: string;
  placeholder: string;
  inputValue?: string;
  onFocus?: () => void;
  onChange?: (param: string) => void;
  inputFocusBlur?: boolean;
  children?: React.ReactNode;
}
const FormTextarea = (props: IFormTextarea) => {
  const { id, placeholder, inputValue, inputFocusBlur, onFocus, onChange } = props;

  const [resizeTextArea, setResizeTextArea] = useState({
    initialHeight: 0,
    initialScrollHeight: 0,
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const elementHeight = (element: HTMLTextAreaElement) => {
    return parseFloat(getComputedStyle(element, null).height.replace("px", ""));
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e?.target?.value);
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = resizeTextArea.initialHeight + "px";
      let newHeight =
        resizeTextArea.initialHeight +
        textAreaRef.current.scrollHeight -
        resizeTextArea.initialScrollHeight;
      newHeight = Math.max(newHeight, resizeTextArea.initialHeight);
      const maxHeight =
        parseFloat(getComputedStyle(textAreaRef.current, null).maxHeight.replace("px", "")) || 160;
      textAreaRef.current.style.height = Math.min(newHeight, maxHeight) + "px";
    }
  };

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      const initHeight = elementHeight(textAreaRef.current);
      const initScrollHeight = textAreaRef.current.scrollHeight;
      setResizeTextArea({ initialHeight: initHeight, initialScrollHeight: initScrollHeight });
      textAreaRef.current.style.height = `${initHeight}px`;
    }
  }, []);

  useEffect(() => {
    if (inputValue?.length === 0 && textAreaRef && textAreaRef.current) {
      // default 2.8rem height
      textAreaRef.current.style.height = `44.8px`;
    }
  }, [inputValue]);

  useEffect(() => {
    inputFocusBlur && textAreaRef?.current?.blur();
  }, [inputFocusBlur]);

  return (
    <Form.Group className="form-group-text-area">
      <div className="input-with-icon-right">
        <Form.Control
          id={id}
          className="form-input"
          as="textarea"
          rows={1}
          type="text"
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={(event) => handleChange(event)}
          ref={textAreaRef}
          value={inputValue}
        />
        <div>{props?.children}</div>
      </div>
    </Form.Group>
  );
};

export default FormTextarea;
