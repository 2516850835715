import React, { useEffect, useState } from "react";
import { ITraceDetails } from "../../../../../models/TraceModel";
import TraceService from "../../../../../services/Trace.service";
import { useTraceStore } from "../../../../../contexts/traces.context";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import refreshIcon from "../../../../../assets/images/refresh.svg";
import "./TraceProperties.scss";
import { observer } from "mobx-react-lite";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TracePropertyDetails from "../TracePropertyDetails/TracePropertyDetails";
import { AxiosResponse } from "axios";
export interface ITraceProperties {
  traceId: string;
  terminalId?: string;
}
const TraceProperties = observer((props: ITraceProperties) => {
  const [traceDetails, setTraceDetails] = useState<ITraceDetails>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const traceService = new TraceService();
  const traceStore = useTraceStore();
  const fetchTraceDetails = () => {
    setLoading(true);
    if (props?.terminalId && props?.traceId) {
      traceService
        .getTraceDetails(props?.terminalId, props?.traceId)
        .then((response: AxiosResponse) => {
          traceStore?.setTraceDetails(response.data);
          setTraceDetails(response.data);
          setLoading(false);
          setError(false);
        })
        .catch((e) => {
          console.error("Error while fetching trace details", e);
          setLoading(false);
          setError(true);
        });
    }
  };
  const loadTraceDetails = () => {
    const details = traceStore?.traceDetails[props?.traceId];
    if (details) {
      setTraceDetails(details);
      setLoading(false);
    } else {
      fetchTraceDetails();
    }
  };
  useEffect(() => {
    loadTraceDetails();
  }, [props?.traceId]);
  return (
    <div className="trace-property">
      {loading && (
        <div className="trace-property-loader">
          <Preloader />
        </div>
      )}
      {!loading &&
        !error &&
        traceDetails?.traceProperties &&
        traceDetails?.traceProperties?.length > 0 && (
          <TracePropertyDetails
            traceProperties={traceDetails?.traceProperties}
            terminalId={props?.terminalId}
          />
        )}
      {error && !loading && (
        <div className="trace-property-error">
          <span>{i18nInstance.t("TTM.followup.error.dataLoad")}</span>
          <img
            className="refresh-icon"
            title={i18nInstance.t("TTM.followup.refreshData")}
            src={refreshIcon}
            onClick={fetchTraceDetails}
          />
        </div>
      )}
    </div>
  );
});

export default TraceProperties;
