import React, { useEffect, useRef, useState } from "react";
import "./NewConversationThread.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import FormInput from "../../atoms/FormInput/FormInput";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import NewMessage from "../../molecules/NewMessage/NewMessage";
import { IMessageError, INewSendMessage } from "../../../models/MessageModel";

export interface INewConversationThread {
  sendMsgLoading: boolean;
  msgError?: IMessageError;
  activeThreadId: string;
  setActiveThreadId?: (id: string) => void;
  onSendMessage: (newMsg: INewSendMessage) => void;
  onClose?: () => void;
}

const NewConversationThread = (props: INewConversationThread) => {
  const { sendMsgLoading, msgError, activeThreadId, setActiveThreadId, onClose, onSendMessage } =
    props;

  const [subjectText, setSubjectText] = useState("");

  const handleSendMessage = (newMsg: INewSendMessage) => {
    subjectText && subjectText.length > 0 && (newMsg.subject = subjectText.trim());
    onSendMessage(newMsg);
  };

  useEffect(() => {
    if (msgError !== undefined && !msgError.error) {
      setSubjectText("");
    }
  }, [msgError]);

  const handleOnEscape = (event: KeyboardEvent) => {
    try {
      event.key === "Escape" ? onClose?.() : null;
    } catch (error) {
      console.log("handleOnEscape ~ error:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleOnEscape);
    return () => {
      document.removeEventListener("keydown", handleOnEscape);
    };
  }, []);

  return (
    <div className="new-message-thread-container">
      <div className="new-message-thread-header">
        <span>{i18nInstance.t("TTM.followup.messages.startNewThread")}</span>
        <CloseIcon className="new-message-thread-close cursor-pointer" onClick={onClose} />
      </div>
      <FormInput
        id={"new-thread-textInput"}
        placeholder={i18nInstance.t("TTM.followup.messages.subjectPlaceHolder")}
        inputValue={subjectText}
        autoFocus={true}
        maxLength={100}
        onFocus={() => setActiveThreadId?.("new-thread-conversation")}
        onChange={(text) => setSubjectText(text)}
      />
      <NewMessage
        id={"new-thread-conversation"}
        isSubjectEmpty={subjectText.trim().length === 0}
        showLoader={sendMsgLoading}
        msgError={msgError}
        activeThreadId={activeThreadId}
        setActiveThreadId={setActiveThreadId}
        onSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default NewConversationThread;
