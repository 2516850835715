import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { handleError } from "../../common/helpers/ErrorHandler";
import { useAppStore } from "../../contexts/app.context";
import { AppService } from "../../services/app.services";
import { ERROR_CUSTOMER_NOT_SELECTED } from "../../common/constants";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import AuthService from "../../services/Auth.service";
import MessageService from "../../services/Message.service";
import { usePushletClient } from "../../hooks/usePushletClient/usePushletClient";
import { getAppConfig } from "../../common/utils";
import { observer } from "mobx-react-lite";

const MasterPageTemplate = observer(() => {
  const appStore = useAppStore();
  const { tokens } = useContext(AuthContext);
  const authService = new AuthService();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorObj, setErrorObj] = useState({ state: false, message: "" });
  const [initPushClient, pushTerminalsNotification] = usePushletClient();
  const messageService = new MessageService();
  const getUnreadCount = () => {
    messageService
      .getTotalUnreadMessagesCount()
      .then((response) => {
        if (response?.data && response.data.unreadCount >= 0) {
          appStore?.setMsgUnreadCount?.(response.data.unreadCount);
        }
      })
      .catch((e) => {
        console.log("getUnreadCount ~ Error", e);
      });
  };
  const loadVehicles = () => {
    messageService.getVehicleList().then((res) => {
      appStore?.setVehiclesList?.(res.data || []);
      if (res?.data?.length > 0) {
        initPushClient(res.data);
      }
      getUnreadCount();
    });
  };
  const loadActivityOptions = () => {
    authService
      .getActivityOptions()
      .then((response) => {
        appStore?.setActivityTypes(response?.data || []);
        setLoading(false);
        loadVehicles();
      })
      .catch((e) => {
        const ERR_CODE = e?.response?.data?.errorCode || "";
        let ERR_MSG = handleError(e);
        if (ERR_CODE === ERROR_CUSTOMER_NOT_SELECTED) {
          ERR_MSG = i18nInstance.t("TTM.followup.error.selectCustomer");
        }
        setErrorObj({ state: true, message: ERR_MSG });
        setLoading(false);
      });
  };
  const playNotificationSound = () => {
    try {
      const notifySound = new Audio(
        `${process.env.PUBLIC_URL}/assets/audio/notification-sound.mp3`,
      );
      const audioPromise = notifySound.play();
      audioPromise.catch((error) => {
        console.log(`Error in playing notification sound - ${error}`);
      });
    } catch (error) {
      console.log(`Error in playing notification sound`);
    }
  };
  useEffect(() => {
    if (pushTerminalsNotification && pushTerminalsNotification.length > 0) {
      const currentTime = new Date().getTime();
      appStore?.setMsgLastUpdatedTime(currentTime);
      appStore?.setVehiclesNotificationsList(pushTerminalsNotification);
      getAppConfig().notifyViasoundEnabled && playNotificationSound();
    }
  }, [pushTerminalsNotification]);
  useEffect(() => {
    if (appStore?.msglastUpdatedTime) {
      getUnreadCount();
    }
  }, [appStore?.msglastUpdatedTime]);
  useEffect(() => {
    tokens && AppService.authorize(tokens.access_token);
    loadActivityOptions();
  }, [tokens]);
  return (
    <div className="master-view d-flex align-items-center justify-content-center w-100 h-100">
      {isLoading ? (
        <Preloader />
      ) : errorObj.state ? (
        <div className="m-3">
          <span>{errorObj.message}</span>
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
});
export default MasterPageTemplate;
