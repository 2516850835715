import React, { useState } from "react";
import "./VehicleCoordinate.scss";
import check from "../../../../../assets/images/check.svg";
import { LngLat } from "../../../../../models/VehicleModel";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { coordinateToDegree, sendMonitoringLogs } from "../../../../../common/utils";
import Toast from "@trimbleinc/modus-react-bootstrap/esm/Toast";
import coordinate from "../../../../../assets/images/coordinates.svg";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import { ReactComponent as Copy } from "../../../../../assets/images/content_copy.svg";

export interface IVehicleCoordinate {
  coordinates: LngLat;
}

const VehicleCoordinate = (props: IVehicleCoordinate) => {
  const { coordinates } = props;

  const getFormattedCoordinates = (data: LngLat) => {
    let result = "";
    if (data.lat) {
      result += `${coordinateToDegree(data.lat)}`;
    }
    if (data.lon) {
      result += `${data.lat ? "," : ""} ${coordinateToDegree(data.lon)}`;
    }
    return result;
  };

  const degLatLon = getFormattedCoordinates(coordinates);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      if (navigator) {
        await navigator?.clipboard?.writeText?.(degLatLon).then(() => {
          setIsCopied(true);
        });
        sendMonitoringLogs("VEHICLE_MAP_MARKER_VEHICLE_LOCATION_COPY");
      }
    } catch (error) {
      console.log("Error while copying data-->", error);
    }
  };

  return (
    <>
      {degLatLon && (
        <div className="coordinate coordinate-container">
          <img src={coordinate} title={i18nInstance.t("TTM.followup.coordinates")} />
          <div className="coordinate-value ml-1">{degLatLon}</div>
          <div className={`copy ${isCopied ? "active" : ""}`}>
            <Copy
              className="copy-icon"
              title={i18nInstance.t("TTM.followup.copy")}
              onClick={handleCopyClick}
            />
          </div>
          <div>
            <Toast
              animation
              delay={1500}
              autohide={true}
              show={isCopied}
              className="popup-toast popup-toast-success"
              onClose={() => setIsCopied(false)}
            >
              <img src={check} className="check-icon" />
              {i18nInstance.t("TTM.followup.copySuccess")}
            </Toast>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuth(VehicleCoordinate);
