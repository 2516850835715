import React from "react";
import "./TimeLineIcon.scss";
import { STATE } from "../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TaskInProgress from "./Icons/TaskInProgress";
import TaskIcon from "./Icons/TaskIcon";
import TaskComplete from "./Icons/TaskComplete";

export interface ITimeLineProps {
  status: number;
  order?: number;
  isNextTask?: boolean;
}

const TimeLine = (props: ITimeLineProps) => {
  const timeLineIcon = () => {
    if (props.status === STATE.FINISHED || props.status === STATE.REMOVED) {
      return <TaskComplete taskOrder={props?.order} />;
    } else if (props.status === STATE.BUSY) {
      return <TaskInProgress taskOrder={props?.order} />;
    } else {
      return <TaskIcon taskOrder={props?.order} />;
    }
  };
  return (
    <div className="timeline-icon-position ">
      <span title={i18nInstance.t(`TTM.followup.task.${STATE[props.status]?.toLowerCase()}`)}>
        {timeLineIcon()}
      </span>
      <span>{props.isNextTask ? <div className="vertical-line flex"> </div> : <></>}</span>
    </div>
  );
};

export default TimeLine;
