import React from "react";
import "./Badge.scss";
export interface IBadge {
  count: number;
}
const limit = 100;
const Badge = (props: IBadge) => {
  const count = props.count > 0 && props.count < limit ? props?.count : `${limit - 1}+`;
  return (
    <div className="badge-value" title={`${props.count}`}>
      <span>{count}</span>
    </div>
  );
};

export default Badge;
