import React from "react";
import Moment from "react-moment";
import { HOURS_MINUTES_FORMAT, STATE } from "../../../common/constants";
import { getPrefixedTimeDifference } from "../../../common/utils";
import "./TaskArrivalTimeline.scss";

export interface ITaskArrivalProps {
  status: number;
  actual: number;
  scheduled: number;
  expected: number;
}

const TaskArrivalTimeline = (props: ITaskArrivalProps) => {
  const { actual, scheduled, expected, status } = props;
  const actualTime = status === STATE.FINISHED ? actual : expected;
  const onTime = actualTime === scheduled;
  const style = actualTime && scheduled && actualTime < scheduled ? "task-early" : "task-delay";
  const timeDiff = actualTime && scheduled && getPrefixedTimeDifference(actualTime, scheduled);
  return (
    <>
      {onTime && actualTime && <Moment local date={actualTime} format={HOURS_MINUTES_FORMAT} />}
      {!onTime && scheduled && <Moment local date={scheduled} format={HOURS_MINUTES_FORMAT} />}
      {timeDiff && <div className={`${style}`}>{timeDiff}</div>}
      {!onTime && actualTime && (
        <Moment
          local
          className={`${timeDiff ? style : ""}`}
          date={actualTime}
          format={HOURS_MINUTES_FORMAT}
        />
      )}
    </>
  );
};

export default TaskArrivalTimeline;
