import React from "react";
import { ReactComponent as Driver } from "./images/driver.svg";
import { ReactComponent as CoDriver } from "./images/codriver.svg";
import { ReactComponent as Passenger } from "./images/passenger.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { CREW_MEMBER_TYPE } from "../../../common/constants";
export interface ICrewIconProps {
  type: string;
  color?: string;
}
const CrewIcon = (props: ICrewIconProps) => {
  const crewType = props?.type?.toLowerCase();
  const title = i18nInstance.t(`TTM.followup.${props?.type?.toLowerCase()}`);
  const { color } = props;
  return (
    <>
      {props.type ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          className={crewType}
        >
          {crewType === CREW_MEMBER_TYPE.DRIVER && <Driver fill={color} title={title} />}
          {crewType === CREW_MEMBER_TYPE.CO_DRIVER && (
            <CoDriver stroke={color} fill={color} title={title} />
          )}
          {crewType === CREW_MEMBER_TYPE.PASSENGER && (
            <Passenger stroke={color} fill={color} title={title} />
          )}
        </svg>
      ) : (
        <></>
      )}
    </>
  );
};
export default CrewIcon;
