import React from "react";
import "./Card.scss";

export interface ICard {
  title?: string;
  value?: string;
}

const Card = (props: ICard) => {
  return (
    <div className="card-container" id={props?.title}>
      {props?.title && (
        <span className="card-title truncate-text" title={props?.title}>
          {props?.title}
        </span>
      )}
      {props?.value && (
        <span className="card-value truncate-text" title={props?.value}>
          {props?.value}
        </span>
      )}
    </div>
  );
};

export default Card;
