import React, { useEffect, useState } from "react";
import "./TraceTypesSearch.scss";
import MultiSelect, { IMultiSelect } from "../../../../molecules/MultiSelect/MultiSelect";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import close from "../../../../../assets/images/close.svg";
import { sendMonitoringLogs } from "../../../../../common/utils";
import { isEqual } from "lodash";
interface ITraceTypeSearch {
  onClose?: (val?: any) => void;
}

const TraceTypeSearch = (props: ITraceTypeSearch) => {
  const traceStore = useTraceStore();
  const isMobile = useMobileDetect();
  const [filteredTraceTypes, setFilteredTraceTypes] = useState<IMultiSelect[]>([]);
  const traceTypes = traceStore?.mappedTraces || [];

  const handleOnApply = () => {
    try {
      if (
        filteredTraceTypes.length > 0 &&
        !isEqual(filteredTraceTypes, traceStore?.traceFilter?.traceTypes)
      ) {
        traceStore?.setTraceFilter({
          ...traceStore.traceFilter,
          traceTypes: filteredTraceTypes,
        });
      }
      isMobile && props.onClose?.();
      sendMonitoringLogs(
        "HISTORY_FILTER_TRACE_TYPES",
        filteredTraceTypes.map((trace) => trace?.label)?.join(","),
      );
    } catch (error) {
      console.log("traceTypeSearch ~ handleOnApply ~ error:", error);
    }
  };

  // In mobile, we don't want to reset the trace types on clear.
  // We clear, only when user deselects the trace types and closes the filter panel.
  const handleOnClear = () => {
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_CLEAR");
    setFilteredTraceTypes([]);
    !isMobile &&
      traceStore?.setTraceFilter({
        ...traceStore.traceFilter,
        traceTypes: [],
      });
  };

  useEffect(() => {
    if (traceStore?.traceFilter?.traceTypes && traceStore?.traceFilter?.traceTypes?.length > 0) {
      setFilteredTraceTypes(traceStore?.traceFilter?.traceTypes);
    }
  }, []);

  return (
    <div className={`trace-types-multiselect${isMobile ? "-mob" : ""}`}>
      {isMobile && (
        <div className="trace-types-header">
          <span>{i18nInstance.t("TTM.followup.traces.tracesTypeList")}</span>
          <img
            src={close}
            className="close-icon"
            alt={i18nInstance.t("TTM.followup.close")}
            onClick={() => props?.onClose?.(filteredTraceTypes)}
          />
        </div>
      )}
      {traceTypes && traceTypes.length > 0 && (
        <div className={`${isMobile && "trace-types-mob"}`}>
          <MultiSelect
            label={""}
            enableSearch
            showApplyButton
            options={traceTypes}
            defaultIsOpen={isMobile}
            value={filteredTraceTypes}
            placeholder={i18nInstance.t("TTM.followup.traces.filter.tracesTypes")}
            onApply={handleOnApply}
            onClear={handleOnClear}
            onChange={setFilteredTraceTypes}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TraceTypeSearch);
