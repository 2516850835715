import React, { useState } from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { IContact } from "../../../models/VehicleDetailsModel";
import upArrow from "../../../assets/images/arrow_up.svg";
import downArrow from "../../../assets/images/arrow_down.svg";
import phone from "../../../assets/images/phone.svg";
import contact from "../../../assets/images/contact.svg";
import "./ContactView.scss";

interface IContactProps {
  data: IContact;
  iconHeight?: number;
}

const ContactDetails = (props: IContactProps) => {
  const [isContactActive, setIsContactActive] = useState(false);
  const arrowIcon = isContactActive ? upArrow : downArrow;
  return (
    <div className="col mt-1">
      {props?.data && (props?.data?.contact || props?.data?.phone) ? (
        <div
          className="row contact cursor-pointer"
          onClick={() => setIsContactActive(!isContactActive)}
        >
          <div>
            {i18nInstance.t("TTM.followup.contactInformation")}
            {<img className="ml-1" height={props.iconHeight} src={arrowIcon} />}
          </div>
        </div>
      ) : (
        <> </>
      )}
      {isContactActive && props.data && (
        <div className="contact_container">
          {props?.data?.contact && (
            <div>
              <img src={contact} className="mr-2" />
              {props?.data?.contact}
            </div>
          )}
          {props?.data?.phone && (
            <div>
              <img src={phone} className="mr-2" />
              <a href={`tel:${props?.data?.phone}`}>{props?.data?.phone}</a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
