import React from "react";
import "./DrivingTimesSummary.scss";
import RowList from "../../../../global/organisms/RowList/RowList";
import TachoStatusIcon from "../../../../molecules/TachoStatusIcon/TachoStatusIcon";
import { ReactComponent as SummationIcon } from "../../../../../assets/images/sum.svg";
import { getHoursAndMinutes, millisecondsToMinutes } from "../../../../../common/utils";
import {
  DEFAULT_TIME,
  DRIVING_TYPES,
  DRIVING_TYPES_COLORS,
  SUMMATION,
} from "../../../../../common/constants";
import { IDrivingTimeSummary, ISummary } from "../../../../../models/TraceModel";

export interface IProps {
  drivingTimesSummary: IDrivingTimeSummary[];
}
const SUMMARIES_LIST = [
  DRIVING_TYPES.DRIVING,
  DRIVING_TYPES.WORKING,
  DRIVING_TYPES.RESTING,
  DRIVING_TYPES.WAITING,
  SUMMATION,
];

const DrivingTimesSummary = (props: IProps) => {
  const { drivingTimesSummary } = props;

  const getSummaryIcon = (typeId: string) => {
    const isSummation = typeId.toLowerCase() === SUMMATION;
    return isSummation ? (
      <SummationIcon title={SUMMATION} />
    ) : (
      <TachoStatusIcon
        type={typeId}
        color={DRIVING_TYPES_COLORS[typeId as keyof typeof DRIVING_TYPES_COLORS]}
      />
    );
  };

  const getSummaryData = (summaries: ISummary[], typeId: string) => {
    const summary = summaries.find((summary) => summary.typeId === typeId);
    return {
      id: typeId,
      value: summary ? getHoursAndMinutes(millisecondsToMinutes(summary.value)) : DEFAULT_TIME,
      icon: getSummaryIcon(typeId),
    };
  };

  return (
    <div className="driving-time-summary-container">
      {drivingTimesSummary?.length > 0 &&
        drivingTimesSummary?.map((driverSummary: IDrivingTimeSummary, index: number) => {
          return (
            <div className="driving-time-summary" key={driverSummary.id}>
              <span
                className="driving-time-summary-title truncate-text"
                title={driverSummary?.name}
              >
                {`${driverSummary?.name} :`}
              </span>
              <RowList
                showIcon={index == 0}
                label={driverSummary.name}
                data={SUMMARIES_LIST.map((type) => getSummaryData(driverSummary.summaries, type))}
              />
            </div>
          );
        })}
    </div>
  );
};

export default DrivingTimesSummary;
