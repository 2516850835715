import React, { useEffect, useState } from "react";
import { DropdownButton } from "@trimbleinc/modus-react-bootstrap";
import "./FormDropdown.scss";
import Dropdown from "@trimbleinc/modus-react-bootstrap/Dropdown";
import close from "../../../assets/images/close.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export interface IFormDropdownOption {
  id: string;
  name: string;
}

export interface IFormDropdown {
  id: string;
  title: string;
  options?: IFormDropdownOption[];
  selected?: string;
  showClearIcon?: boolean;
  onClear?: () => void;
  onSelectOption: (option: IFormDropdownOption) => void;
}
const FormDropdown = (props: IFormDropdown) => {
  const { id, title, options, selected, showClearIcon, onSelectOption, onClear } = props;

  const [selectedOption, setSelectedOption] = useState<IFormDropdownOption>();

  const getOptionFromId = (id: string | undefined) => {
    if (id) {
      return options?.find((option: IFormDropdownOption) => option.id === id);
    }
  };

  const handleSelectedOption = (id: string | null) => {
    if (id) {
      const option = getOptionFromId(id);
      option && setSelectedOption(option);
      option && onSelectOption(option);
    }
  };

  const handleOnClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedOption(undefined);
    onClear?.();
  };

  useEffect(() => {
    selected && handleSelectedOption(selected);
    return () => {
      setSelectedOption(undefined);
    };
  }, [selected]);

  return (
    <DropdownButton
      variant="dropdown-basic-button"
      id={`dropdown-basic-button ${id}`}
      title={
        <div className="dropdown-title truncate-text">
          {selectedOption ? (
            <>
              <span title={selectedOption?.name} className="dropdown-value truncate-text">
                {selectedOption?.name}
              </span>
              {showClearIcon && (
                <img
                  src={close}
                  className="close-icon dropdown-clear"
                  title={i18nInstance.t("TTM.followup.clear")}
                  onClick={handleOnClear}
                />
              )}
            </>
          ) : (
            title
          )}
        </div>
      }
    >
      {options &&
        options.length > 0 &&
        options.map((option) => (
          <Dropdown.Item
            key={option.id}
            eventKey={option.id}
            className="dropdown-option truncate-text"
            onSelect={handleSelectedOption}
            title={option.name}
          >
            {option.name}
          </Dropdown.Item>
        ))}
    </DropdownButton>
  );
};

export default FormDropdown;
