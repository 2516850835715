import React from "react";
import { COLOR_VALUES, DRIVING_TYPES } from "../../../common/constants";
import { ReactComponent as DrivingElement } from "../../../assets/images/tacho_status/driving.svg";
import { ReactComponent as RestingElement } from "../../../assets/images/tacho_status/resting.svg";
import { ReactComponent as WorkingElement } from "../../../assets/images/tacho_status/working.svg";
import { ReactComponent as WaitingElement } from "../../../assets/images/tacho_status/waiting.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
export interface ITachoStatusIconProps {
  type?: string;
  color?: string;
  dimensions?: { width: string; height: string };
}

const defaultProps: ITachoStatusIconProps = {
  dimensions: { width: "28", height: "28" },
};

const TachoStatusIcon = (props: ITachoStatusIconProps) => {
  const { type = DRIVING_TYPES.DRIVING, color = COLOR_VALUES.WHITE } = props;
  const title = i18nInstance.t(`TTM.followup.tachoStatus.${type?.toLowerCase()}`);
  const typeTemplate = () => {
    return type ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.dimensions?.width}
        height={props?.dimensions?.height}
        viewBox="0 0 28 28"
        className={type}
      >
        {type === DRIVING_TYPES.RESTING && <RestingElement title={title} fill={color} />}
        {type === DRIVING_TYPES.WORKING && <WorkingElement title={title} fill={color} />}
        {type === DRIVING_TYPES.WAITING && <WaitingElement title={title} stroke={color} />}
        {type === DRIVING_TYPES.DRIVING && (
          <DrivingElement title={title} stroke={color} fill={color} />
        )}
      </svg>
    ) : (
      <></>
    );
  };
  return <>{typeTemplate()}</>;
};
TachoStatusIcon.defaultProps = defaultProps;
export default TachoStatusIcon;
