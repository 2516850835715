import React from "react";
import "./FileThumbnail.scss";
import attachmentClear from "../../../../assets/images/close.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";

export interface IFileAttachment {
  isLoading?: boolean;
  file?: File;
  onClear?: () => void;
}

const FileAttachment = (props: IFileAttachment) => {
  const { isLoading, file, onClear } = props;
  const src = file && window?.URL?.createObjectURL?.(file);
  return (
    <div className="attachment-wrapper">
      <img
        src={attachmentClear}
        title={i18nInstance.t("TTM.followup.clear")}
        className="attachment-clear"
        onClick={onClear}
      />
      {isLoading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <>
          {file?.type?.includes("image/") && (
            <img className="thumbnail thumbnail-image" src={src} />
          )}
          {file?.type?.includes("application/pdf") && (
            <iframe className="thumbnail thumbnail-pdf" src={src} />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(
  FileAttachment,
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);
