import React from "react";
import ReactDOM from "react-dom";
import Shell from "./shell/Shell";
import { AppConfig, getAppConfig, loadScript } from "./common/utils";
import "./sass/App.scss";
import { FetchService } from "./services/app.services";

FetchService.axios
  .get(`${process.env.PUBLIC_URL}/config/config.json?v=${process.env.REACT_APP_VERSION}`)
  .then((appconfig) => {
    window.appConfig = appconfig.data as AppConfig;
    ReactDOM.render(
      <React.StrictMode>
        <Shell />
      </React.StrictMode>,
      document.getElementById("root"),
    );
    const appConfig = getAppConfig();
    if (appConfig.monitoring?.enabled === "true" && appConfig.monitoring?.props?.scriptSrc) {
      initMonitoring().then(() => {
        const MONITORING_CONFIG = {
          clientToken: appConfig.monitoring.props.clientToken,
          applicationId: appConfig.monitoring.props.applicationId,
          site: appConfig.monitoring.props.site,
          service: appConfig.monitoring.props.service,
          env: appConfig.monitoring.props.environment,
          version: process.env.REACT_APP_VERSION,
          sampleRate: 100,
          sessionReplaySampleRate: 100, // if not included, the default is 100
          trackResources: true,
          trackLongTasks: true,
          trackInteractions: appConfig.monitoring.props?.trackInteractions || false,
        };
        if (window.DD_RUM) {
          window.DD_RUM.init(MONITORING_CONFIG);
          window.DD_RUM.startSessionReplayRecording();
        }
      });
    }
  })
  .catch((e) => {
    console.log(`error fetching app config - ${e}`);
  });

function initMonitoring() {
  const appConfig = getAppConfig();
  return new Promise((resolve, reject) => {
    loadScript(appConfig.monitoring.props.scriptSrc, "datadog-js")
      .then(() => {
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
