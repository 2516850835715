import { AxiosError } from "axios";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { ERROR_CODE } from "../constants";
const getStatusErrorMessage = (status: number): string => {
  let errMessage = "";
  switch (status) {
    case ERROR_CODE.UNAUTHORIZED:
    case ERROR_CODE.FORBIDDEN: {
      errMessage = i18nInstance.t("TTM.followup.error.unAuthorized");
      break;
    }
    case ERROR_CODE.NOT_FOUND:
      errMessage = i18nInstance.t("TTM.followup.error.noDataAvailable");
      break;
    default: {
      errMessage = i18nInstance.t("TTM.followup.error.internalError");
    }
  }
  return errMessage;
};
export const handleError = (e: Error): string => {
  let errMessage = i18nInstance.t("TTM.followup.error.internalError");
  if (e && e instanceof AxiosError) {
    const HTTP_STATUS_CODE = e.response?.status;
    if (HTTP_STATUS_CODE) {
      errMessage = getStatusErrorMessage(HTTP_STATUS_CODE);
    }
  }
  return errMessage;
};
