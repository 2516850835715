import React, { useState, useEffect } from "react";
import "./DispatchMessageModal.scss";
import { observer } from "mobx-react-lite";
import { degreeToCoordinate, sendMonitoringLogs } from "../../../common/utils";
import { useAppStore } from "../../../contexts/app.context";
import { ISearchResult } from "../SearchView/SearchViewModel";
import Form from "@trimbleinc/modus-react-bootstrap/esm/Form";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import Modal from "@trimbleinc/modus-react-bootstrap/esm/Modal";
import Button from "@trimbleinc/modus-react-bootstrap/esm/Button";
import Spinner from "@trimbleinc/modus-react-bootstrap/esm/Spinner";
import VehicleService from "../../../services/Vehicle.service";
import { TOAST_STYLE } from "../../../common/constants";
import ToastWrapper, {
  defaultToastObj,
  IToast,
} from "../../application/molecules/ToastWrapper/ToastWrapper";
import { useDispatchStore } from "../../../contexts/dispatch.context";
import { IMessage } from "../../../models/VehicleModel";

export interface IModalProps {
  terminalId?: string;
  location?: ISearchResult;
}

const DispatchMessageModal = (props: IModalProps) => {
  const appStore = useAppStore();
  const dispatchStore = useDispatchStore();

  const [dispatchSubject, setDispatchSubject] = useState<string>(
    i18nInstance.t("TTM.followup.dispatch.defaultSubject"),
  );
  const [dispatchText, setDispatchText] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [loading, setIsLoading] = useState<boolean>(false);

  const [dispatchToast, setDispatchToast] = useState<IToast>(defaultToastObj);

  const vehicleService = new VehicleService();

  const handleClose = () => {
    appStore?.setShowModal(false);
  };

  const handleSendMessage = () => {
    if (props?.location && props?.location?.coordinate && props?.terminalId) {
      setIsLoading(true);
      const data: IMessage = {
        subject: dispatchSubject,
        body: dispatchText,
        description: props?.location?.name,
        destination: {
          lat: degreeToCoordinate(props?.location?.coordinate?.lat),
          lon: degreeToCoordinate(props?.location?.coordinate?.lon),
        },
      };
      vehicleService
        .sendMessage(props.terminalId, data)
        .then((response) => {
          if (response) {
            appStore?.setShowModal(false);
            setIsLoading(false);
            dispatchStore?.setToastProps({
              showToast: true,
              message: `${i18nInstance.t("TTM.followup.dispatch.message.success")}`,
              style: TOAST_STYLE.SUCCESS,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
          setDispatchToast({
            showToast: true,
            message: `${i18nInstance.t("TTM.followup.generic.error")}`,
            style: TOAST_STYLE.ERROR,
          });
        });
      sendMonitoringLogs("VEHICLE_DYNAMIC_DISPATCHING_SEND_MESSAGE");
    }
  };

  useEffect(() => {
    if (dispatchSubject) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [dispatchSubject, dispatchText]);

  return (
    <Modal show={true} onHide={handleClose} centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title className="truncate-text dispatch-title" id="modalTitle">
          {i18nInstance.t("TTM.followup.dispatch.modalTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label className="dispatch-label" id="dispatchSubjectLabel">
              {`${i18nInstance.t("TTM.followup.subject")}`}
              <span className="mandate-field">{"*"}</span>
            </Form.Label>
            <Form.Control
              id="dispatchSubject"
              as="input"
              autoFocus
              maxLength={100}
              autoComplete="off"
              value={dispatchSubject}
              onChange={(input) => setDispatchSubject(input?.target?.value)}
              placeholder={`${i18nInstance.t("TTM.followup.dispatch.subjectPlaceholder")}`}
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label className="dispatch-label" id="dispatchTextLabel">
              {`${i18nInstance.t("TTM.followup.text")}`}
            </Form.Label>
            <Form.Control
              className="textArea"
              id="dispatchText"
              as="textarea"
              autoComplete="off"
              value={dispatchText}
              onChange={(input) => setDispatchText(input?.target?.value)}
              placeholder={`${i18nInstance.t("TTM.followup.dispatch.textPlaceholder")}`}
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label className="dispatch-label" id="dispatchLocationLabel">
              {`${i18nInstance.t("TTM.followup.search.location")}`}
              <span className="mandate-field">{"*"}</span>
            </Form.Label>
            <Form.Control
              id="dispatchLocation"
              as="input"
              disabled
              autoComplete="off"
              value={props?.location?.name}
            ></Form.Control>
          </Form.Group>
          <div className="dispatch-toast">
            <ToastWrapper
              onClose={() => setDispatchToast(defaultToastObj)}
              toastProps={dispatchToast}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" id="closeBtn" onClick={handleClose}>
          {i18nInstance.t("TTM.followup.close")}
        </Button>
        <Button
          variant="primary"
          id="sendBtn"
          disabled={isBtnDisabled || loading}
          onClick={handleSendMessage}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="spinner mr-2 text-white"
            />
          )}
          {i18nInstance.t("TTM.followup.send")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(DispatchMessageModal);
