import React from "react";
import { PATH, PERMISSIONS } from "../../common/constants";
import { Route, Navigate, Outlet } from "react-router-dom";
import { TraceProvider } from "../../contexts/traces.context";
import TracesWrapper from "../../components/application/containers/Traces/TracesWrapper/TracesWrapper";
import TracesTimeline from "../../components/application/organisms/Traces/TracesTimeline/TracesTimeline";
import TracesContainer from "../../components/application/containers/Traces/TracesContainer/TracesContainer";
import TraceVehiclesPanel from "../../components/application/organisms/Traces/TraceVehiclesPanel/TraceVehiclesPanel";
import TracesMobileContainer from "../../components/application/containers/Traces/TracesMobileContainer/TracesMobileContainer";
import TracesContentContainer from "../../components/application/containers/Traces/TracesContentContainer/TracesContentContainer";
import TracesDesktopContainer from "../../components/application/containers/Traces/TracesDesktopContainer/TracesDesktopContainer";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import useMobileDetect from "../../hooks/useMobileDetect/useMobileDetect";
import TracesMapMobileContainer from "../../components/application/containers/Traces/TracesMapMobileContainer/TracesMapMobileContainer";
import TracesTimelineMobileContainer from "../../components/application/containers/Traces/TracesTimelineMobileContainer/TracesTimelineMobileContainer";

const TracesModule = React.lazy(() => import("../../components/application/pages/Traces/Traces"));

export const traceRoutes = () => {
  const isMobile = useMobileDetect();
  return (
    <Route
      path={PATH.TRACES}
      element={
        <ProtectedRoute permission={PERMISSIONS.TRACES}>
          <TraceProvider>
            <TracesModule />
          </TraceProvider>
        </ProtectedRoute>
      }
    >
      {isMobile ? (
        <>
          <Route path="" element={<TraceVehiclesPanel />} />
          <Route path="" element={<TracesMobileContainer />}>
            <Route path="" element={<TracesContainer />}>
              <Route path={":unitId"} element={<Outlet />} />
              <Route path={":unitId/:date"} element={<TracesMapMobileContainer />} />
              <Route path={":unitId/:date/timeline"} element={<TracesTimelineMobileContainer />} />
            </Route>
          </Route>
        </>
      ) : (
        <>
          <Route path="" element={<TracesWrapper />}>
            <Route path="" element={<TracesDesktopContainer />} />
            <Route path={":unitId"} element={<Outlet />} />
            <Route path={":unitId/:date"} element={<TracesContainer />}>
              <Route path="" element={<TracesContentContainer />} />
              <Route path={":traceTypeId"} element={<TracesContentContainer />} />
            </Route>
          </Route>
        </>
      )}
      <Route path="*" element={<Navigate to={PATH.TRACES} replace />} />
    </Route>
  );
};
