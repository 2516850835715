import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
interface IProps {
  count?: number;
  width?: number | string;
  height?: number;
}
const defaultProps: IProps = {
  count: 1,
};
const SkeletonComponent = (props: IProps) => (
  <SkeletonTheme baseColor="#D0D0D7" highlightColor="#F3F3F7">
    <Skeleton count={props.count} width={props.width} height={props.height} />
  </SkeletonTheme>
);
SkeletonComponent.defaultProps = defaultProps;
export default SkeletonComponent;
