import { useState, useEffect, useRef, useContext } from "react";
import { Event, EventSourcePolyfill, EventSourcePolyfillInit } from "event-source-polyfill";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import { getPrefix, getVisibilityEvent } from "../../common/utils";

export interface IRefreshOptions {
  enabled?: boolean;
  interval?: number;
}
export interface IEventSoure {
  src: {
    url: string;
    options?: EventSourcePolyfillInit;
    autoRefresh?: IRefreshOptions;
    refreshOnVisibilityChange?: boolean;
  };
}
/**
 * useEventSource - Custom hooks to connect to event stream and react to events
 * open - connection successfully established
 * message - receive stream data and parse to extract the data and use it for component display
 * error - Based on status we will mark it as error or completed event. Once after all messages are retrieved we will get an listener here. Finally close the connection.
 */
export const useEventSource = ({ src }: IEventSoure) => {
  const { tokens } = useContext(AuthContext);
  const tokenRef = useRef<string | undefined>("");
  const [source, setSource] = useState<EventSourcePolyfill>();
  const [data, setData] = useState<any>();
  const [consolidatedData, setConsolidatedData] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const timerRef = useRef<null | undefined | number>(null);
  const visibilityEventRef = useRef<boolean>(false);

  let consolidatedLocalData = <any>[];
  /**
   * Method to clear the setInterval and timerRef values
   */
  function cleanupTimerInterval() {
    timerRef.current && clearInterval(timerRef.current);
    timerRef.current = null;
  }
  /**
   * Method to create new EventSource with params
   * if source exists earlier, we will remove the event listeners and create new one
   */
  function createEventSource() {
    setIsCompleted(false);
    setError(false);
    if (source) {
      removeEventListener(source);
    }
    const options = {
      headers: {
        Authorization: `Bearer ${tokenRef?.current}`,
        ["Accept-Language"]: tokens?.profile?.language || navigator?.language.slice(0, 2),
      },
      withCredentials: true,
    };
    const eventSource = new EventSourcePolyfill(src.url, options);
    setSource(eventSource);
  }
  function refreshOnDemand() {
    timerRef.current = Math.random();
    consolidatedLocalData = [];
    createEventSource();
  }
  function triggerPolling() {
    try {
      const intervalID: any = setInterval(() => {
        consolidatedLocalData = [];
        createEventSource();
      }, src.autoRefresh?.interval);
      timerRef.current = intervalID;
    } catch (error) {
      console.log("Error in polling eventsource", error);
    }
  }
  /**
   * Method to handle visibility event trigger from browser window actions. When minimized or swithcing to different tabs (not swithching between windows)
   * we will check the visibilityState and based on it perform the action
   * - visible - initialize connection and trigger polling mechansim
   * - hidden - Cleanup the interval and when user returns back we will have the state as visible and can see the updated data with polling of reactive streams at regulat intervals
   */
  function handleVisibilityEvent() {
    if (document.visibilityState === "visible") {
      refreshOnDemand();
      if (!timerRef.current && src?.autoRefresh?.enabled === true) {
        triggerPolling();
      }
    } else {
      cleanupTimerInterval();
    }
  }
  //Method to handle open
  function handleOnOpen(e: Event) {
    console.log("stream open", e);
  }
  /**
   * Method to handle error part of the connection
   * If the event contains status and not equals to 200, then we can consider it as an error and mark the status as error
   * Else mark the stream as completed and if polling enabled we will publish the combinedDataset and trigger autoRefresh (polling) mechanism
   * @param e
   */
  function handleOnError(e: any) {
    if ((e?.type === "error" && e?.error !== undefined) || (e.status && e.status !== 200)) {
      console.log("stream error", e);
      setError(true);
    } else {
      console.log("stream completed", e);
      setIsCompleted(true);
      if (src?.autoRefresh?.enabled === true) {
        timerRef.current && setConsolidatedData(consolidatedLocalData);
        if (!timerRef.current) {
          triggerPolling();
        }
      } else {
        if (timerRef.current) {
          setConsolidatedData(consolidatedLocalData);
          timerRef.current = null;
        }
      }
    }
    source?.close();
  }
  /**
   * Method to handle stream response from message event handler.
   * data - need to parse the data and based on polling option we might need append to the state values
   * On initialize, we will send the message whenever its received to the component
   * On AutoRefresh, we need to combine the data and push it on completion event
   * @param response
   */
  function handleOnMessage(response: any) {
    try {
      const responseData = JSON.parse(response.data);
      if (responseData) {
        if (timerRef.current) {
          consolidatedLocalData.push(responseData);
        } else {
          setData(responseData);
        }
      }
    } catch (error) {
      console.log("handleOnMessage error", error);
    }
  }
  /**
   * Method to remove event listeners attached to the event source
   */
  function removeEventListener(source: EventSource) {
    if (source) {
      source.removeEventListener("open", handleOnOpen);
      source.removeEventListener("message", handleOnMessage);
      source.removeEventListener("error", handleOnError);
    }
  }
  /**
   * Method to attach event listeners to event source
   */
  function createListener() {
    if (source) {
      source.addEventListener("open", handleOnOpen);
      source.addEventListener("message", handleOnMessage);
      source.addEventListener("error", handleOnError);
    }
  }

  useEffect(() => {
    if (source) {
      createListener();
    }
  }, [source]);

  useEffect(() => {
    if (tokens && tokens.access_token) {
      tokenRef.current = tokens.access_token;
    }
  }, [tokens]);

  /**
   * Method to initialize stream call and attach document visibility event
   * OnDestroy - cleanup the interval and event listeners
   */
  useEffect(() => {
    if (!src.url) return;
    tokenRef.current = tokens?.access_token;
    const prefix = getPrefix();
    const visibilityEvent = getVisibilityEvent(prefix);
    createEventSource();
    if (!visibilityEventRef?.current && src?.refreshOnVisibilityChange === true) {
      document.addEventListener(visibilityEvent, handleVisibilityEvent);
      visibilityEventRef.current = true;
    }
    return () => {
      if (source) {
        removeEventListener(source);
      }
      document.removeEventListener(visibilityEvent, handleVisibilityEvent);
      tokenRef.current = "";
      cleanupTimerInterval();
    };
  }, [src.url]);

  return [source, data, isCompleted, error, consolidatedData, refreshOnDemand];
};
