import React from "react";
import { ICrew } from "../../../models/VehicleModel";
import Dash from "../../atoms/Dash/Dash";
import CrewIcon from "../CrewIcon/CrewIcon";
import "./VehicleCrew.scss";
export interface IVehicleCrewProps {
  data: ICrew[];
}
const defaultProps: IVehicleCrewProps = {
  data: [],
};
const VehicleCrew = (props: IVehicleCrewProps) => {
  const crewList =
    props.data && props.data.length > 0 ? (
      props.data.map((crew: ICrew, index: number) => {
        return (
          <div key={index} className="mr-2 mt-1 d-flex crew flex-grow-1">
            <CrewIcon type={crew.type} />
            <span className="ml-1 truncate-text crew__name" title={crew.name}>
              {crew.name}
            </span>
          </div>
        );
      })
    ) : (
      <Dash />
    );
  return <div className="d-flex flex-row flex-wrap">{crewList}</div>;
};
VehicleCrew.defaultProps = defaultProps;
export default VehicleCrew;
