import { OverlayTrigger, Tooltip } from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import DateTime from '../../../../molecules/DateTime/DateTime';
import { ReactComponent as Reefer } from '../../../../../assets/images/reefer.svg';
import i18nInstance from '@ttl/shared-react-library/src/i18n';
import { getTranslatedValue } from '../../../../../common/utils';
import { IReefer } from '../../../../../models/VehicleModel';
import { VEHICLE_REEFER_STATUS } from '../../../../../common/constants';

export interface IReeferEngineProps {
  reefer: IReefer;
}

const ReeferEngine = (props: IReeferEngineProps) => {
  const separator = ":";
  return (
    <OverlayTrigger 
      placement="auto"
      trigger={["hover", "click"]}
      delay={{ show: 300, hide: 0 }}
      overlay={props?.reefer?.timestamp ? (
        <Tooltip id="vehcile-reefer-tooltip">
          <span className="tooltip-info">
            {`${i18nInstance.t("TTM.followup.lastUpdated")} ${separator} `}
            <DateTime dateTime={props?.reefer?.timestamp} />
          </span>
        </Tooltip>
        ) : <span/>}
      >
        <div className="vehicle-property vehicle-reefer">
          <div className="mt-1">
            <span className="mr-1">
              <Reefer className={`${props?.reefer?.status.toLowerCase() === VEHICLE_REEFER_STATUS.ON ? "svg-reefer-on" : ""}`}/> 
            </span>
            {`${i18nInstance.t(`TTM.followup.reeferEngine`)} ${separator} `}
            {getTranslatedValue("TTM.followup.", props?.reefer?.status.toLowerCase())}
          </div>
        </div>
    </OverlayTrigger>
  )
};

export default ReeferEngine;