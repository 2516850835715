import { useEffect, useRef } from "react";

export interface IUseSwipe {
  elementRef: React.RefObject<HTMLDivElement>;
  isEnabled?: boolean;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
}

export const useSwipe = (src: IUseSwipe) => {
  const touchStartRef = useRef<number>();
  const touchEndRef = useRef<number>();

  const minSwipeDistance = 100;

  const handleOnTouchStart = (e: TouchEvent) => {
    try {
      touchEndRef.current = 0;
      touchStartRef.current = e?.targetTouches[0]?.clientX;
    } catch (error) {
      console.log("onTouchStart ~ error:", error);
    }
  };

  const handleOnTouchMove = (e: TouchEvent) => {
    try {
      touchEndRef.current = e?.targetTouches[0]?.clientX;
    } catch (error) {
      console.log("onTouchMove ~ error:", error);
    }
  };

  const handleOnTouchEnd = () => {
    try {
      if (!touchStartRef.current || !touchEndRef.current) return;
      const distance = touchStartRef.current - touchEndRef.current;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      isLeftSwipe && src.onSwipeLeft();
      isRightSwipe && src.onSwipeRight();
    } catch (error) {
      console.log("onTouchEnd ~ error:", error);
    }
  };

  useEffect(() => {
    if (src.isEnabled) {
      src.elementRef?.current?.addEventListener("touchstart", handleOnTouchStart);
      src.elementRef?.current?.addEventListener("touchmove", handleOnTouchMove);
      src.elementRef?.current?.addEventListener("touchend", handleOnTouchEnd);
    }
    return () => {
      src.elementRef?.current?.removeEventListener("touchstart", handleOnTouchStart);
      src.elementRef?.current?.removeEventListener("touchmove", handleOnTouchMove);
      src.elementRef?.current?.removeEventListener("touchend", handleOnTouchEnd);
    };
  }, []);
};
