import React, { createContext, useContext, useMemo } from "react";
import { VehicleStore } from "../stores/vehicleStore/vehicles.store";

const VehiclesContext = createContext<VehicleStore | null>(null);
export const useVehicleStore = () => useContext(VehiclesContext);

export const VehiclesProvider = ({ children }: any) => {
  const vehiclesStore = useMemo(() => new VehicleStore(), []);
  return <VehiclesContext.Provider value={vehiclesStore}>{children}</VehiclesContext.Provider>;
};
export default VehiclesContext;
