import { action, makeObservable, observable } from "mobx";
import { IListData } from "../../components/molecules/AutoComplete/AutoComplete";
import { IMessageType } from "../../models/MessageModel";

export class MessageStore {
  vehicleList: IListData[] = [];
  showModal = false;
  messageTypes: IMessageType[] = [];
  maxFileSize = 10000000;
  constructor() {
    makeObservable(this, {
      vehicleList: observable,
      showModal: observable,
      messageTypes: observable,
      maxFileSize: observable,
      setVehicleList: action,
      setShowModal: action,
      setMessageTypes: action,
      setMaxFileSize: action,
    });
  }
  setVehicleList(list: IListData[]) {
    this.vehicleList = list;
  }
  setShowModal(flag: boolean) {
    this.showModal = flag;
  }
  setMessageTypes(messageTypes: IMessageType[]) {
    this.messageTypes = messageTypes;
  }
  /**
   * Fn to set the maximum allowed file size.
   * @param fileSize in bytes.
   */
  setMaxFileSize(fileSize: number) {
    this.maxFileSize = fileSize;
  }
}
