import React from "react";
import "./VehicleZones.scss";
import { IZone } from "../../../../../models/VehicleModel";
import { getTranslatedValue, isWarning } from "../../../../../common/utils";
import { MEASUREMENT_UNITS } from "../../../../../common/constants";
import Dash from "../../../../atoms/Dash/Dash";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import warningIcon from "../../../../../assets/images/warning.svg";
import doorOpenIcon from "../../../../../assets/images/door_open.svg";
import doorClosedIcon from "../../../../../assets/images/door_closed.svg";
import { OverlayTrigger, Tooltip } from "@trimbleinc/modus-react-bootstrap";
import DateTime from "../../../../molecules/DateTime/DateTime";

export enum ViewMode {
  LIST = "list",
  CARD = "card",
}
export interface IVehicleZonesProps {
  zones: IZone[];
  viewMode?: ViewMode;
}
const defaultProps: IVehicleZonesProps = {
  zones: [],
  viewMode: ViewMode.LIST
};
const vehicleZones = (props: IVehicleZonesProps) => {
  const { zones, viewMode = ViewMode.LIST } = props;
  const zoneList =
    zones && zones.length > 0 ? (
      zones.map((zoneItem: IZone, index: number) => {
        const { zone: zoneLabel, temp, high, low, tempTimestamp, doorStatus, doorStatusTimestamp } = zoneItem;
        const warning = isWarning(temp, low, high);
        const warningStyle = warning ? "warning" : "";
        const icon = warning ? (
          <img
            src={warningIcon}
            className="warningIcon ml-1"
            title={i18nInstance.t("TTM.followup.warning")}
          />
        ) : null;
        const zoneTemp = temp || temp === 0 ? `${temp} ${MEASUREMENT_UNITS.TEMPERATURE}` : "";
        const separator = zoneLabel ? ":" : "";
        return (
          <div key={index} className={`zone ${warningStyle}`}>
            {zoneLabel && (
              <div className={`d-flex flex-row truncate-text zone-label`}>
                <div title={`${zoneLabel}`}>{zoneLabel}</div>
                {viewMode === ViewMode.CARD && doorStatus != null ? (
                  <OverlayTrigger
                    placement="auto"
                    trigger={["hover", "click"]}
                    delay={{ show: 300, hide: 0 }}
                    overlay={
                      <Tooltip id= "zone-icon-tooltip">
                        <div className="d-flex flex-column align-items-start tooltip-info">
                          <span>{getTranslatedValue("TTM.followup.", doorStatus ? "doorOpen" : "doorClosed")}</span>
                          {doorStatusTimestamp ? (
                            <span>
                              {`${i18nInstance.t("TTM.followup.lastUpdated")} : `}
                              <DateTime dateTime={doorStatusTimestamp} />
                            </span>
                          ) : null}
                        </div>
                      </Tooltip>
                    }
                  >
                    <span className="ml-2 zone-icon">
                      <img src={doorStatus ? doorOpenIcon : doorClosedIcon}/>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </div>
            )}
            {viewMode !== ViewMode.CARD ? separator : null}
            <div className={`truncate-text zone-value`}>
              {
                <OverlayTrigger 
                  placement="auto"
                  trigger={["hover", "click"]}
                  delay={{ show: 300, hide: 0 }}
                  overlay={tempTimestamp ? (
                    <Tooltip id= "zone-value-tooltip">
                      <div className="d-flex flex-column align-items-start tooltip-info">
                        <span>{zoneTemp}</span>
                        <span>
                          {`${i18nInstance.t("TTM.followup.lastUpdated")} : `}
                          <DateTime dateTime={tempTimestamp} />
                        </span>
                      </div>
                    </Tooltip>
                  ) : <span/>}
                >
                  <span>{zoneTemp}</span>
                </OverlayTrigger>
              }
              {icon}
            </div>
          </div>
        );
      })
    ) : (
      <Dash />
    );
  return <div className={`d-flex flex-row zone-wrapper row-gap ${viewMode}`}>{zoneList}</div>;
};
vehicleZones.defaultProps = defaultProps;
export default vehicleZones;
