import React from "react";
import TracesListPanel from "../../../organisms/Traces/TracesListPanel/TracesListPanel";
import TwoColumnTemplate from "../../../../global/template/TwoColumnTemplate/TwoColumnTemplate";
import TwoRowTemplate from "../../../../global/template/TwoRowTemplate/TwoRowTemplate";
import TracesTimeline from "../../../organisms/Traces/TracesTimeline/TracesTimeline";
import TracesMapDesktopContainer from "../TracesMapDesktopContainer/TracesMapDesktopContainer";
import { getAppConfig } from "../../../../../common/utils";

const TracesContentContainer = () => {
  const appConfig = getAppConfig();
  return (
    <TwoColumnTemplate
      leftPane={{
        component: (
          <TwoRowTemplate
            topPane={{
              component: <TracesMapDesktopContainer />,
              rowHeight: appConfig?.tracesTimeline ? "h-70" : "h-100",
            }}
            bottomPane={{
              component: <TracesTimeline />,
              rowHeight: "h-30",
            }}
          />
        ),
        colSpan: 10,
      }}
      rightPane={{
        component: <TracesListPanel />,
        colSpan: 2,
      }}
    />
  );
};

export default TracesContentContainer;
