import React, { useEffect, useState } from "react";
import "./FileInput.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { ReactComponent as AttachmentIcon } from "../../../../assets/images/message/attachment.svg";

export interface IFileInput {
  id: string;
  maxSize?: number;
  fileFormats: string;
  onFileSelect: (file: any) => void;
  onFileError: (error: string) => void;
  onFileLoadStart?: () => void;
}
const FileInput = (props: IFileInput) => {
  const { id, maxSize, fileFormats, onFileSelect, onFileError, onFileLoadStart } = props;
  const reader = new FileReader();
  let readerTimer: any = null;

  const [fileLoaded, setFileLoaded] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    const ele = document?.getElementById?.(`input-attachment-${id}`);
    ele?.click();
  };

  const handleOnLoadStart = () => {
    try {
      onFileLoadStart?.();
      setFileLoaded(false);
    } catch (error) {
      console.log("handleOnLoadStart ~ error:", error);
    }
  };

  const handleOnLoadError = () => {
    try {
      onFileError(i18nInstance.t("TTM.followup.messages.attachment.generic.error"));
    } catch (error) {
      console.log("handleOnLoadError ~ error", error);
    }
  };

  const handleOnLoadEnd = () => {
    try {
      readerTimer = setTimeout(() => {
        setFileLoaded(true);
        removeEventListeners();
        clearTimeout(readerTimer);
      }, 100);
    } catch (e) {
      console.log("handleOnLoadEnd ~ error", e);
    }
  };

  function removeEventListeners() {
    reader?.removeEventListener?.("loadstart", handleOnLoadStart);
    reader?.removeEventListener("error", handleOnLoadError);
    reader?.removeEventListener("loadend", handleOnLoadEnd);
  }

  const addReaderEventListeners = () => {
    reader?.addEventListener?.("loadstart", handleOnLoadStart);
    reader?.addEventListener?.("loadend", handleOnLoadEnd);
    reader?.addEventListener?.("error", handleOnLoadError);
  };

  const handleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event?.target) {
        event?.stopPropagation();
        setFile(undefined);
        const file: File | undefined = event.target?.files?.[0];
        event.target.value = "";
        if (file && maxSize && file?.size <= maxSize) {
          if (file.name?.length <= 100) {
            setFile(file);
            addReaderEventListeners();
            reader?.readAsDataURL?.(file);
          } else {
            onFileError(i18nInstance.t("TTM.followup.messages.attachment.name.error"));
          }
        } else {
          onFileError(
            i18nInstance.t("TTM.followup.messages.attachment.size.error", {
              maxSize: maxSize && maxSize / 1000000,
            }),
          );
        }
      }
    } catch (error) {
      onFileError(i18nInstance.t("TTM.followup.messages.attachment.generic.error"));
    }
  };

  useEffect(() => {
    if (fileLoaded && file) {
      onFileSelect(file);
    }
    return () => {
      clearTimeout(readerTimer);
      reader?.removeEventListener?.("loadstart", handleOnLoadStart);
      reader?.removeEventListener("error", handleOnLoadError);
      reader?.removeEventListener("loadend", handleOnLoadEnd);
    };
  }, [fileLoaded]);

  return (
    <div className="attachment-icon" onClick={handleOnClick}>
      {/* Improvement: Icon to be extended based on the props. */}
      <AttachmentIcon title={i18nInstance.t("TTM.followup.messages.attachment.attachFile")} />
      <input
        type="file"
        id={`input-attachment-${id}`}
        accept={fileFormats}
        hidden
        onChange={(e) => handleOnChange(e)}
      />
    </div>
  );
};

export default FileInput;
