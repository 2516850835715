import React from "react";
import { getFormattedText } from "../../../../common/formattingUtils";
import DocumentLink from "../DocumentLink/DocumentLink";
import { ITextFormat } from "../../../../common/models";

/* Breaks the string into array of string matching the style pattern.
    input text: This is a photo [sample|doc://fileKey] message.
    output array: ["This is a photo ", "sample", "fileKey"]"
*/
const DOCUMENT_REFERENCE_PATTERN = /(.*?)\[([^\\|]+)\|doc:\/\/(.*?)\]/;

const DocumentReferenceFormatter = (props: ITextFormat) => {
  const formattedAttachment = (text: string) => {
    const groups = getFormattedText(text, DOCUMENT_REFERENCE_PATTERN, props.type);
    if (groups && groups.length > 0) {
      return groups?.map((item, index) => {
        if (typeof item == "string") {
          return item;
        } else {
          return (
            <span key={`${item?.[2] + index}`}>
              {item?.[1]}
              <DocumentLink name={item?.[2]} fetchDownloadUrl={`${props.linkPrefix}${item?.[3]}`} />
            </span>
          );
        }
      });
    } else {
      return text;
    }
  };
  const messageRenderer = (text: string) => {
    if (text) {
      return text
        .replace(/\r/g, "\n")
        .split("\n")
        .map((text, index) => (
          <div key={`${text + index}`} className="attachment-sent">
            {formattedAttachment(text)}
          </div>
        ));
    }
  };

  const formattedMessage = messageRenderer(props.text);
  return <span className="w-100">{formattedMessage}</span>;
};

export default DocumentReferenceFormatter;
