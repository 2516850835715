import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { PATH, PERMISSIONS } from "../../common/constants";
import Details from "../../components/templates/Details/Details";
import { VehiclesProvider } from "../../contexts/vehicles.context";

const VehiclesModule = React.lazy(() => import("../../components/pages/Vehicles/Vehicles"));

export const vehicleRoutes = () => {
  return (
    <Route
      path={`${PATH.VEHICLES}`}
      element={
        <ProtectedRoute permission={PERMISSIONS.FOLLOWUP}>
          <VehiclesProvider>
            <VehiclesModule />
          </VehiclesProvider>
        </ProtectedRoute>
      }
    >
      {/* Nested route path to handle the vehicle details panel */}
      <Route path={`${PATH.VEHICLES}/:vehicleId`} element={<Details />} />
    </Route>
  );
};
