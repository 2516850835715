import React from "react";
import { IDrivingTimeCompensation } from "../../../models/VehicleModel";
import { getFormattedHoursAndMinutes } from "../../../common/utils";
import moment from "moment";
import { DATE_FORMAT } from "../../../common/constants";
import "./RestCompensationContainer.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
export interface IRestCompensation {
  restCompenstaions: IDrivingTimeCompensation[];
}
const RestCompensationContainer = (props: IRestCompensation) => {
  const restCompensationTitle = i18nInstance.t(
    "TTM.followup.drivingTimes.reducedWeeklyRestCompensation",
  );
  const compensationHours = i18nInstance.t("TTM.followup.drivingTimes.compensationHours");
  const compensationDueDate = i18nInstance.t("TTM.followup.drivingTimes.compensationDueDate");
  return (
    <div className="paper-container">
      <div className="paper-title pl-2">
        <span className="truncate-text" title={restCompensationTitle}>
          {restCompensationTitle}
        </span>
      </div>
      <div className="compensation-wrapper">
        <div className="compensation-header">
          <div className="compensation-info" title={compensationHours}>
            <span className="truncate-text">{compensationHours}</span>
          </div>
          <div className="compensation-info" title={compensationDueDate}>
            <span className="truncate-text">{compensationDueDate}</span>
          </div>
        </div>
        <div className="compensation-container">
          {props?.restCompenstaions?.map((compensation, index) => (
            <div key={`${index}` + `${compensation.amount}`} className="compensation-details">
              <span className="compensation-info">
                {getFormattedHoursAndMinutes(compensation.amount)}
              </span>
              <span className="compensation-info">
                {moment(compensation.dueDate).format(DATE_FORMAT)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default RestCompensationContainer;
