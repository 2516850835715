import React, { ReactElement } from "react";
import "./BadgeIcon.scss";
import Badge from "../Badge/Badge";

export interface IBadgeIcon {
  badgeTitle?: string;
  iconSrc?: ReactElement;
  count?: number;
  handleClick?: () => void;
}
const BadgeIcon = (props: IBadgeIcon) => {
  return (
    <div className="badge-icon" onClick={props.handleClick} title={props.badgeTitle}>
      {props.iconSrc}
      {props.count ? (
        <div className="badge">
          <Badge count={props.count} />
        </div>
      ) : null}
    </div>
  );
};

export default BadgeIcon;
