import moment from "moment";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
export const getDuration = (starttime: number): string => {
  let displayVal = "";
  try {
    const currentTime = moment();
    //const tempTime = moment("2021-10-27 16:07:50").valueOf();
    const activityStartTime = moment(starttime);
    const duration = moment.duration(currentTime.diff(activityStartTime));
    const [years, months, days, hours, minutes, seconds] = [
      duration.years(),
      duration.months(),
      duration.days(),
      duration.hours(),
      duration.minutes(),
      duration.seconds(),
    ];
    //console.log(years, months, days, hours, minutes, seconds);
    if (years >= 1) {
      displayVal = `${years} ${i18nInstance.t("TTM.DateTime.suffix.year")}`;
    } else if (years === 0 && months > 0) {
      displayVal = `${months} ${i18nInstance.t("TTM.DateTime.suffix.month")}`;
    } else if (years === 0 && months === 0 && days >= 3) {
      displayVal = `${days} ${i18nInstance.t("TTM.DateTime.suffix.day")}`;
    } else if (years === 0 && months === 0 && days >= 1 && days < 3) {
      displayVal = `${days} ${i18nInstance.t("TTM.DateTime.suffix.day")} ${
        hours > 0 ? `${hours} ${i18nInstance.t("TTM.DateTime.suffix.hour")}` : ""
      }`;
    } else if (years === 0 && months === 0 && days === 0 && hours > 0) {
      displayVal = `${hours} ${i18nInstance.t("TTM.DateTime.suffix.hour")} ${
        minutes > 0 ? `${minutes} ${i18nInstance.t("TTM.DateTime.suffix.minute")} ` : ""
      }`;
    } else if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes > 0) {
      displayVal = `${minutes} ${i18nInstance.t("TTM.DateTime.suffix.minute")} ${
        seconds > 0 ? `${seconds} ${i18nInstance.t("TTM.DateTime.suffix.second")}` : ""
      }`;
    } else if (
      years === 0 &&
      months === 0 &&
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds > 0
    ) {
      displayVal = `${seconds} ${i18nInstance.t("TTM.DateTime.suffix.second")}`;
    }
  } catch (error) {
    displayVal = "";
  }

  return displayVal;
};
