import React from "react";
import "./ToastWrapper.scss";
import Toast from "@trimbleinc/modus-react-bootstrap/esm/Toast";

export interface IToast {
  showToast: boolean;
  message: string;
  style: string;
  delay?: number;
}

export const defaultToastObj: IToast = {
  showToast: false,
  message: "",
  style: "",
};
export interface IToastProps {
  onClose: () => void;
  toastProps: IToast;
}

const ToastWrapper = (props: IToastProps) => {
  return (
    <Toast
      className={`toast toast-${props.toastProps.style}`}
      delay={props.toastProps.delay}
      show={props.toastProps.showToast}
      autohide={true}
      onClose={() => props.onClose()}
    >
      {props.toastProps.message && <div className="toast-message">{props.toastProps.message}</div>}
      <button
        type="button"
        className="close toast-close"
        data-dismiss="toast"
        onClick={() => props.onClose()}
      >
        <i className="modus-icon material-icons">{"close"}</i>
      </button>
    </Toast>
  );
};

export default ToastWrapper;
