import { useEffect, useRef } from "react";

export type Key = "Enter" | "Escape";

/**
 * Custom hook to listen to key press specified by the user and call a callback function.
 * @param key - key to listen on the keydown.
 * @param callbackFn - callback function to be called on keypress specified.
 */
export const useKeyPress = (
  elementRef: React.RefObject<HTMLDivElement>,
  key: Key,
  callbackFn: any,
) => {
  const eventListenerRef = useRef<any>();

  const handleKeydown = (event: KeyboardEvent) => {
    if (eventListenerRef) {
      eventListenerRef.current(event);
    }
  };

  useEffect(() => {
    try {
      eventListenerRef.current = (event: KeyboardEvent) => {
        switch (key) {
          case "Enter":
            event.key === "Enter" && !event.shiftKey && callbackFn(event);
            break;
          case "Escape":
            event.key === "Escape" && callbackFn(event);
            break;
          default:
            break;
        }
      };
    } catch (error) {
      console.log("useKeyPress ~ error:", error);
    }
  }, [key, callbackFn]);

  useEffect(() => {
    elementRef.current && elementRef.current.addEventListener("keydown", handleKeydown);
    return () => {
      elementRef.current && elementRef.current.removeEventListener("keydown", handleKeydown);
    };
  }, []);
};
