import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import "./MultiSelect.scss";
import { MultiSelect as Select } from "react-multi-select-component";
import Form from "@trimbleinc/modus-react-bootstrap/Form";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { Button } from "@trimbleinc/modus-react-bootstrap";
import useOnOutsideClick from "@utilityjs/use-on-outside-click";

export interface IMultiSelect {
  label: string;
  value: string;
}

export interface IMultiSelectProps {
  options: IMultiSelect[];
  value: IMultiSelect[];
  label: string;
  placeholder?: string;
  defaultIsOpen?: boolean;
  enableSearch?: boolean;
  showApplyButton?: boolean;
  onApply?: () => void;
  onClear?: () => void;
  onChange?: Dispatch<SetStateAction<IMultiSelect[]>>;
}

const defaultProps: IMultiSelectProps = {
  enableSearch: false,
  showApplyButton: false,
  options: [],
  value: [],
  label: "",
};

export const valueRendererItems = (items: IMultiSelect[]) => {
  if (items.length > 3) {
    return `${i18nInstance.t("TTM.followup.multiSelectValue", {
      item1: items[0].label,
      item2: items[1].label,
      item3: items[2].label,
      count: items.length - 3,
    })} `;
  } else {
    return items.map((i, index) => "\xa0" + `${i.label}${items.length - 1 === index ? "" : ","} `);
  }
};

const MultiSelect = (props: IMultiSelectProps) => {
  const {
    options,
    value,
    label,
    enableSearch,
    showApplyButton,
    defaultIsOpen,
    placeholder,
    onChange,
    onApply,
    onClear,
  } = props;

  const applyBtnRef = useRef<HTMLButtonElement>(null);
  const multiSelectRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnApply = () => {
    onApply?.();
    setIsExpanded(false);
  };

  const handleSearchInput = (evt: Event) => {
    try {
      evt?.stopPropagation();
      evt?.preventDefault();
      setIsExpanded(true);
    } catch (error) {
      console.log("handleSearchInput ~ error:", error);
    }
  };

  const handleOnMultiselectClick = () => {
    try {
      !defaultIsOpen && setIsExpanded(!isExpanded);
    } catch (error) {
      console.log("handleOnMultiselectClick ~ error:", error);
    }
  };

  const handleOnItemClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, onClick: any) => {
    try {
      setIsExpanded(true);
      evt?.preventDefault();
      evt?.stopPropagation();
      onClick();
    } catch (error) {
      console.log("handleOnItemClick ~ error:", error);
    }
  };

  const handleOnClear = () => {
    onChange?.([]);
    onClear?.();
    isExpanded && setIsExpanded(true);
  };

  const handleOnChange = (val: IMultiSelect[], onChange: any) => {
    try {
      val.length > 0 ? onChange?.(val) : handleOnClear();
    } catch (error) {
      console.log("handleOnChange ~ error:", error);
    }
  };

  useEffect(() => {
    try {
      let searchInput: Element;
      let clearSelectionBtn: Element;
      if (isExpanded) {
        setTimeout(() => {
          if (showApplyButton) {
            const options = document?.getElementsByClassName("select-panel")?.[0];
            options?.append(applyBtnRef.current as Node);
          }
          searchInput = document?.querySelectorAll(".search > input")?.[0];
          searchInput?.addEventListener("click", handleSearchInput);

          clearSelectionBtn = document?.getElementsByClassName("clear-selected-button")?.[0];
          clearSelectionBtn?.addEventListener("click", handleOnClear);

          const searchInputClear = document?.querySelectorAll(".search > button")?.[0];
          searchInputClear?.setAttribute("style", "display: none;");
        });

        return () => {
          searchInput?.removeEventListener("click", handleSearchInput);
          clearSelectionBtn?.removeEventListener("click", handleOnClear);
          setIsExpanded(false);
        };
      }
    } catch (error) {
      console.log("multiSelect ~ useEffect ~ error:", error);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (defaultIsOpen) {
      setIsExpanded(true);
    }
  }, [defaultIsOpen]);

  useOnOutsideClick(multiSelectRef, () => {
    try {
      !defaultIsOpen && setIsExpanded(false);
    } catch (error) {
      console.log("useOnOutsideClick ~ error:", error);
    }
  });

  return (
    <Form.Group>
      <div className="multiSelect-label">{label}</div>
      <div ref={multiSelectRef} className="multiSelect" onClick={handleOnMultiselectClick}>
        <Select
          className="theme"
          options={options}
          value={value}
          onChange={(val: IMultiSelect[]) => handleOnChange(val, onChange)}
          hasSelectAll={false}
          disableSearch={!enableSearch}
          labelledBy={label}
          isOpen={isExpanded}
          ItemRenderer={({ checked, option, onClick }: any) => {
            return (
              <div className="multiSelect-item" onClick={(evt) => handleOnItemClick(evt, onClick)}>
                <input type="checkbox" checked={checked} readOnly />
                <label>{option.label}</label>
              </div>
            );
          }}
          valueRenderer={(selected) => {
            return (
              <div className="multiSelect-value cursor-pointer">
                {selected.length > 0 ? (
                  <div title={`${valueRendererItems(value)}`}>{valueRendererItems(value)}</div>
                ) : (
                  <div className="multiSelect-placeHolder">
                    {placeholder || i18nInstance.t("TTM.followup.selectOptions")}
                  </div>
                )}
              </div>
            );
          }}
        />
        <div style={{ display: "none" }}>
          <Button
            variant="primary"
            className="btn btn-primary apply-btn"
            onClick={handleOnApply}
            ref={applyBtnRef}
            disabled={value.length === 0}
          >
            {i18nInstance.t("TTM.followup.apply")}
          </Button>
        </div>
      </div>
    </Form.Group>
  );
};

MultiSelect.defaultProps = defaultProps;
export default MultiSelect;
