export const getFormattedText = (text: string, pattern: RegExp, type: RegExp) => {
  try {
    const matchedArr = text.match(new RegExp(type, "g"));
    return matchedArr?.map((item: string) => {
      const groups = item.match(new RegExp(pattern));
      if (groups && groups?.length > 0) {
        return groups;
      } else {
        return item;
      }
    });
  } catch (error) {
    console.log("Formatting error", error);
  }
};

export const getFormattedPermissions = (data: any) => {
  try {
    return data?.map(
      (i: any) => `${i?.application?.appKey?.toLowerCase?.()}.${i.name?.toLowerCase?.()}`,
    );
  } catch (error) {
    console.log("getFormattedPermissions ~ error:", error);
  }
};
