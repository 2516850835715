import React from "react";
import SkeletonComponent from "../../atoms/Skeleton/SkeletonComponent";
import "./Chip.scss";

export interface IChip {
  icon?: any;
  data: string;
  isLoading?: boolean;
}

const Chip = (props: IChip) => {
  return (
    <>
      {props?.isLoading ? (
        <SkeletonComponent height={24} width={100} />
      ) : (
        <div className="chip-container">
          {props?.icon && <span className="chip-icon">{props?.icon}</span>}
          <span className="chip-text truncate-text" title={props.data}>
            {props.data}
          </span>
        </div>
      )}
    </>
  );
};

export default Chip;
