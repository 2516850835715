import React from "react";
import { Navigate, Route } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { PATH, PERMISSIONS } from "../../common/constants";
import { MessageProvider } from "../../contexts/message.context";
import MessageConversations from "../../components/organisms/MessageConversations/MessageConversations";

const MessageModule = React.lazy(
  () => import("../../components/pages/MessageCentre/MessageCentre"),
);

export const messageRoutes = () => {
  return (
    <Route
      path={PATH.MESSAGES}
      element={
        <ProtectedRoute permission={PERMISSIONS.MESSAGING}>
          <MessageProvider>
            <MessageModule />
          </MessageProvider>
        </ProtectedRoute>
      }
    >
      <Route path={`${PATH.MESSAGES}/unit/:unitId`} element={<MessageConversations />} />
    </Route>
  );
};
