import React, { useEffect, useState } from "react";
import "./Message.scss";
import DateTime from "../DateTime/DateTime";
import { IMessage, IMessageTranslationState } from "../../../models/MessageModel";
import { DOCUMENT_PATTERN, MESSAGE_DIRECTION, TEXT_PATTERN } from "../../../common/constants";
import {
  isMessageUnread,
  getMessageDuration,
  isMessageSentNow,
} from "../../../common/messageUtils";
import MessageStatusIcon from "../MessageStatusIcon/MessageStatusIcon";
import DocumentReferenceFormatter from "../../application/molecules/DocumentReferenceFormatter/DocumentReferenceFormatter";
import DocumentNameFormatter from "../../application/molecules/DocumentNameFormatter/DocumentNameFormatter";
import { getAppConfig, sendMonitoringLogs } from "../../../common/utils";
import { useParams } from "react-router-dom";
import { getFollowupURL, getMessagingURL } from "../../../common/serviceUtils";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export interface IMessageProps {
  message: IMessage;
  showMsgInfo?: boolean;
  showTransition?: boolean;
  translationStates?: IMessageTranslationState;
  handleTranslation: (messageId: string, message: string) => void;
}

const Message = (props: IMessageProps) => {
  const { message, showMsgInfo, showTransition, translationStates, handleTranslation } = props;
  const attachmentFlag = getAppConfig().messageAttachment;
  const [transition, setTransition] = useState<boolean>(false);
  const { isTranslating, translatedMessage } = translationStates || {
    isTranslating: false,
    translatedMessage: "",
  };
  const [toggleTranslate, setToggleTranslate] = useState<boolean>(false);

  const params = useParams();

  const getDirectionCls = (direction: string) =>
    direction === MESSAGE_DIRECTION.TO_DEVICE
      ? `msg-sent ${transition ? "msg-transition" : ""}`
      : "msg-received";

  useEffect(() => {
    if (showTransition && isMessageSentNow(message.timestamp)) {
      setTransition(true);
    }
  }, [showTransition]);

  const handleTranslationOptionClick = () => {
    sendMonitoringLogs("MESSAGE_CONVERSATION_TOGGLE_TRANSLATE");
    setToggleTranslate((prevState) => {
      const newToggleState = !prevState;
      if (!prevState && !translationStates?.translatedMessage) {
        handleTranslation(message.id, message.text);
      }
      return newToggleState;
    });
  };

  useEffect(() => {
    if (!translatedMessage && !isTranslating) {
      setToggleTranslate(false);
    }
  }, [translationStates]);

  const getTranslateOption = (
    isTranslating: boolean,
    toggleTranslate: boolean,
    translatedMessage: string,
  ): string => {
    if (isTranslating) {
      return i18nInstance.t("TTM.followup.messages.translating");
    } else if (toggleTranslate && translatedMessage) {
      return i18nInstance.t("TTM.followup.messages.showOriginal");
    } else {
      return i18nInstance.t("TTM.followup.messages.translate");
    }
  };

  return (
    <div className={`${getDirectionCls(message.direction)}`} id={`message-${message.id}`}>
      <div className="message-container d-flex align-items-center">
        <div className={`msg msg-text ${showMsgInfo ? "msg-tip" : ""}`}>
          {attachmentFlag ? (
            message.direction === MESSAGE_DIRECTION.TO_DEVICE ? (
              <DocumentReferenceFormatter
                text={message.text}
                type={TEXT_PATTERN}
                linkPrefix={getMessagingURL("attachment/downloadUrl?fileKey=")}
              />
            ) : (
              <DocumentNameFormatter
                text={toggleTranslate && translatedMessage ? translatedMessage : message.text}
                type={DOCUMENT_PATTERN}
                linkPrefix={getFollowupURL(`files/terminals/${params?.unitId}/`)}
              />
            )
          ) : (
            <div className="message-text">
              {toggleTranslate && translatedMessage ? translatedMessage : message.text}
            </div>
          )}

          <div className={`msg-time msg-translate`}>
            {message.direction === MESSAGE_DIRECTION.FROM_DEVICE && (
              <>
                {
                  <span
                    className="translate-option mr-2"
                    onClick={() => !isTranslating && handleTranslationOptionClick()}
                  >
                    {getTranslateOption(isTranslating, toggleTranslate, translatedMessage)}
                  </span>
                }
              </>
            )}
            <DateTime displayFormat="hh:mm a" dateTime={message.timestamp} />
            {message.direction === MESSAGE_DIRECTION.TO_DEVICE && (
              <span className="msg-status mx-1">
                <MessageStatusIcon status={message.status} />
              </span>
            )}
          </div>
        </div>
        {isMessageUnread(message) && <span className="msg-unread" />}
      </div>

      {showMsgInfo && (
        <div className="msg-info">
          {getMessageDuration(message.timestamp)}
          {message.sender?.name && (
            <>
              <span className="dot"></span>
              <span title={message.sender?.name}>{message.sender?.name}</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Message;
