import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import AuthService from "../../services/Auth.service";
import { PATH } from "../../common/constants";

const CustomerSwitchTransition = () => {
  const navigate = useNavigate();
  const authService = new AuthService();
  const location = useLocation();
  const handleCustomerSwitch = async () => {
    try {
      const customerId = location?.state?.id || "";
      if (customerId) {
        await authService.getToken(customerId);
        navigate(-1);
      } else {
        navigate(PATH.VEHICLES);
      }
    } catch (error) {
      console.log(`Error in customer switch - ${error}`);
      navigate(PATH.VEHICLES);
    }
  };
  useEffect(() => {
    handleCustomerSwitch();
  }, []);
  return (
    <div className="loader-container">
      <div>
        <Preloader />
      </div>
      <div className="loader-message">{i18nInstance.t("TTM.followup.admin.switchCustomer")}</div>
    </div>
  );
};
export default CustomerSwitchTransition;
