import React from "react";
import SkeletonComponent from "../../atoms/Skeleton/SkeletonComponent";
import "./StrikeThrough.scss";

export interface IStrikeThrough {
  title: string;
  totalElements: number;
  finished?: number;
  content: number | string;
  isLoading?: boolean;
}

const StrikeThrough = (props: IStrikeThrough) => {
  const dataArr = Array(props.totalElements).fill(props.content);
  return (
    <div className="strike-through-container">
      {props.isLoading ? (
        <SkeletonComponent height={47} width={200} />
      ) : (
        <>
          <div className="paper-title strike-through-title" title={props.title}>
            <span className="truncate-text">{props.title}</span>
          </div>
          <div className="d-flex">
            {dataArr.length > 0
              ? dataArr?.map((data, index) => {
                  const isFinished = props.finished !== undefined && index < props.finished;
                  return (
                    <div
                      className={`strike-through-item ${
                        isFinished ? "strike-through-finished" : ""
                      }`}
                      key={`${data + index}`}
                    >
                      <span className="truncate-text strike-through-text" title={data}>
                        {data}
                      </span>
                      {isFinished ? <div className="strike-line"></div> : null}
                    </div>
                  );
                })
              : null}
          </div>
        </>
      )}
    </div>
  );
};

export default StrikeThrough;
