import React, { useEffect, useState } from "react";
import "./ConversationThread.scss";
import {
  IMessage,
  IMessageError,
  IMessageThread,
  INewSendMessage,
} from "../../../models/MessageModel";
import NewMessage from "../../molecules/NewMessage/NewMessage";
import MessagesContainer from "../../molecules/MessagesContainer/MessagesContainer";
import { getUnreadIdsFromViewport, MESSAGE_READ } from "../../../common/messageUtils";
import MessageService from "../../../services/Message.service";
import { useAppStore } from "../../../contexts/app.context";

export interface IConversationThread {
  terminalId: string;
  thread: IMessageThread;
  sendMsgLoading: boolean;
  msgError?: IMessageError;
  activeThreadId: string;
  onSendMessage: (newMsg: INewSendMessage) => void;
  setActiveThreadId?: (id: string) => void;
}

const ConversationThread = (props: IConversationThread) => {
  const {
    thread,
    terminalId,
    msgError,
    activeThreadId,
    sendMsgLoading,
    onSendMessage,
    setActiveThreadId,
  } = props;
  const messageService = new MessageService();
  const [messageThread, setMessageThread] = useState<IMessageThread>();
  const appStore = useAppStore();
  useEffect(() => {
    setMessageThread(thread);
  }, [thread]);

  const updateMsgLastUpdatedTime = () => {
    const CURRENT_TIME = new Date().getTime();
    appStore?.setMsgLastUpdatedTime?.(CURRENT_TIME);
  };

  const handleOnClick = () => {
    if (messageThread) {
      const offsetTop = document.getElementById("conversation-header")?.clientHeight;
      const unreadIds = getUnreadIdsFromViewport(messageThread.messageList, { offsetTop });
      if (unreadIds.length > 0) {
        messageService.updateMessageStatus(terminalId, unreadIds, MESSAGE_READ).then(() => {
          const updatedMessageList = messageThread.messageList.map((message: IMessage) => {
            return unreadIds.includes(message.id)
              ? { ...message, status: MESSAGE_READ }
              : { ...message };
          });
          const updatedThread = updatedMessageList && {
            ...messageThread,
            messageList: updatedMessageList,
          };
          updatedMessageList && setMessageThread(updatedThread);
          updateMsgLastUpdatedTime();
        });
      }
    }
  };

  return (
    <>
      {messageThread && messageThread.messageList?.length > 0 && (
        <div key={messageThread.id} onClick={handleOnClick} className="conversation-thread-wrapper">
          <MessagesContainer
            showTransition={activeThreadId === messageThread.id}
            threadMessageList={messageThread.messageList}
            subject={messageThread.subject}
          />
          <NewMessage
            id={messageThread.id}
            subject={messageThread.subject}
            msgError={msgError}
            showLoader={sendMsgLoading}
            activeThreadId={activeThreadId}
            onSendMessage={onSendMessage}
            setActiveThreadId={setActiveThreadId}
            lastMsgId={messageThread?.messageList?.[messageThread?.messageList?.length - 1]?.id}
          />
        </div>
      )}
    </>
  );
};
export default ConversationThread;
