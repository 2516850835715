import React, { useEffect, useRef, useState } from "react";
import "./AutoComplete.scss";
import { observer } from "mobx-react-lite";
import TextInput from "../TextInput/TextInput";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { getSkeletonArray, virtualizedListScrollTo } from "../../../common/utils";
import SkeletonComponent from "../../atoms/Skeleton/SkeletonComponent";
import HighlightText from "../../atoms/HighlightText/HighlightText";
import RefreshMessageView from "../../organisms/RefreshMessageView/RefreshMessageView";

export interface IListData {
  id: string;
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  //options to add custom properties 
  //without changing the interface structure
  additionalProps?: Map<string, any>; 
}
export interface IAutoComplete {
  showLoader?: boolean;
  showError?: boolean;
  reloadData?: () => void;
  list?: IListData[];
  onListSelect?: (param: IListData) => void;
  rowHeight: number;
  selectedItemId?: string;
  placeholder?: string;
}

const AutoComplete = observer((props: IAutoComplete) => {
  const listRef = useRef<List>(null);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<IListData[]>();
  const [selectedId, setSelectedId] = useState<string>();
  const placeholder = props?.placeholder || i18nInstance.t("TTM.followup.search.vehicleSearch");

  const handleChange = (search: string) => {
    try {
      setSearchText(search);
      const filteredResult = props?.list?.filter((listItem) => {
        const searchText = search.toLowerCase();
        return listItem?.title?.toLowerCase().includes(searchText) || listItem?.subtitle?.toLowerCase().includes(searchText);
      });
      setListData(filteredResult);
    } catch (error) {
      console.log("file: AutoComplete.tsx ~ handleChange ~ error:", error);
    }
  };
  const scrollToSelectedItem = () => {
    props?.selectedItemId &&
      listData &&
      virtualizedListScrollTo(props?.selectedItemId, listData, listRef);
  };
  const handleClearText = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchText("");
    setListData(props?.list);
  };
  useEffect(() => {
    setSelectedId(props?.selectedItemId);
    scrollToSelectedItem();
  }, [props?.selectedItemId]);
  useEffect(() => {
    setListData(props?.list);
    scrollToSelectedItem();
  }, [props?.list]);
  useEffect(() => {
    if (searchText === "") {
      scrollToSelectedItem();
    }
  }, [searchText]);
  return (
    <div className="autocomplete-container">
      {props?.showError && (
        props?.reloadData ? (
          <RefreshMessageView onRefresh={props.reloadData} errorMessage={i18nInstance.t("TTM.followup.error.message")}/>
        ) : (
          <span className="autocomplete-info">{i18nInstance.t("TTM.followup.generic.error")}</span>
        )
      )}

      {!props?.showError ? (
        <>
          <div className="autocomplete-search">
            <TextInput
              id="inputSearch"
              autoFocus
              maxLength={100}
              inputValue={searchText}
              handleInputChange={handleChange}
              handleClearText={handleClearText}
              placeholder={placeholder}
            />
          </div>
          {props?.showLoader ? (
            <div className="m-2">
              <SkeletonComponent count={2} height={35} width={"100%"} />
            </div>
          ) : (
            <>
              {listData && listData?.length > 0 ? (
                <div className="autocomplete-list">
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        itemCount={listData.length || getSkeletonArray().length}
                        itemSize={props?.rowHeight}
                        height={height}
                        width={width}
                        ref={listRef}
                      >
                        {({ index, style }) => (
                          <div
                            style={{ ...style }}
                            className="autocomplete-list-item"
                            onClick={() => props?.onListSelect?.(listData[index])}
                          >
                            <div
                              className={`list-item-container ${
                                selectedId == listData[index].id
                                  ? "list-item-container-selected"
                                  : ""
                              }`}
                            >
                              {listData[index].icon ? listData[index].icon : null}                        
                              <div className="autocomplete-text">
                                {listData[index]?.title ? (
                                  <HighlightText originalText={listData[index]?.title} searchText={searchText} />
                                ): null}
                                {listData[index]?.subtitle ? (
                                  <HighlightText containerCls="autocomplete-subtitle" originalText={listData[index]?.subtitle || ""} searchText={searchText} />
                                ): null}
                              </div>
                            </div>
                          </div>
                        )}
                      </List>
                    )}
                  </AutoSizer>
                </div>
              ) : (
                <span className="autocomplete-info">
                  {i18nInstance.t("TTM.followup.search.noResults")}
                </span>
              )}
            </>
          )}
        </>
      ) : null}
    </div>
  );
});

export default AutoComplete;
