import { getAppConfig } from "./utils";

const getBaseURL = () => {
  return getAppConfig().api;
};

const getUMPBaseURL = () => {
  return getAppConfig().ump.api;
};

const getUMPFilter = () => {
  return getAppConfig().ump.filter;
};

export const getFollowupURL = (suffix: string) => {
  return `${getBaseURL()}/followup/${suffix}`;
};

export const getMessagingURL = (suffix: string) => {
  return `${getBaseURL()}/messaging/${suffix}`;
};

export const getTraceURL = (suffix: string) => {
  return `${getBaseURL()}/history/${suffix}`;
};

export const getPermissionsURL = (suffix: string) => {
  return `${getUMPBaseURL()}/${suffix}?search=${getUMPFilter()}`;
};
