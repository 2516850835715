import React from "react";
import direction from "../../../../../assets/images/direction.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import "./VehicleDirection.scss";

export interface IVehicleDirectionProps {
  heading: number;
}

const VehicleDirection = (props: IVehicleDirectionProps) => {
  const { heading } = props;
  const index = Math.floor(((heading + 22.5) % 360) / 45.0);

  const DIRECTIONS = {
    0: i18nInstance.t("TTM.followup.direction.north"),
    1: i18nInstance.t("TTM.followup.direction.northEast"),
    2: i18nInstance.t("TTM.followup.direction.east"),
    3: i18nInstance.t("TTM.followup.direction.southEast"),
    4: i18nInstance.t("TTM.followup.direction.south"),
    5: i18nInstance.t("TTM.followup.direction.southWest"),
    6: i18nInstance.t("TTM.followup.direction.west"),
    7: i18nInstance.t("TTM.followup.direction.northWest"),
  };

  return (
    <div className="direction direction-container">
      <img src={direction} title={i18nInstance.t("TTM.followup.direction")} />
      <span className="direction-value ml-1">{DIRECTIONS[index as keyof typeof DIRECTIONS]}</span>
    </div>
  );
};

export default VehicleDirection;
