import React from "react";
import "./VehicleMileage.scss";
import mileageSrc from "../../../../../assets/images/mileage.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { MEASUREMENT_UNITS } from "../../../../../common/constants";

interface Props {
  mileage: number;
}

const VehicleMileage = (props: Props) => {
  const { mileage } = props;

  return (
    <div className="mileage mileage-container">
      <img src={mileageSrc} title={i18nInstance.t("TTM.followup.mileage")} />
      <span className="mileage-value">
        {mileage} {MEASUREMENT_UNITS.DISTANCE}
      </span>
    </div>
  );
};

export default VehicleMileage;
