import React from "react";
import "./RefreshButton.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { Button } from "@trimbleinc/modus-react-bootstrap";
import { ReactComponent as RefreshIcon } from "../../../assets/images/refresh.svg";

export interface IRefreshButtonProps {
  onRefresh: () => void;
}

const RefreshButton = (props: IRefreshButtonProps) => {
  return (
    <Button
      size="sm"
      variant="primary"
      className="refresh-btn mt-3"
      onClick={props.onRefresh}
      title={i18nInstance.t("TTM.followup.refresh")}
    >
      <RefreshIcon className="refresh-icon mr-1"/>
      <span>{i18nInstance.t("TTM.followup.refresh")}</span>
    </Button>
  )
};
export default RefreshButton;