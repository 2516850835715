import React from "react";
import "./MessageInfoBar.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../common/utils";
import expandIcon from "../../../assets/images/expand_double_arrow.svg";

export interface IMessageInfoBar {
  count: number;
  participants?: string[];
  handleOnClick?: () => void;
}

const MessageInfoBar = (props: IMessageInfoBar) => {
  const { count, participants, handleOnClick } = props;
  return (
    <div
      className="msg-info-container cursor-pointer"
      onClick={() => {
        handleOnClick?.();
        sendMonitoringLogs("MESSAGES_CONVERSATION_THREAD_TOGGLE");
      }}
    >
      <div className="d-flex align-items-center">
        <div className="circle-wrapper">
          {participants?.map((participant: string, index: number) => {
            return (
              <div
                key={`${participant + index}`}
                className={`circle-container`}
                title={participant}
              >
                <span className="circle-text">{participant[0]}</span>
              </div>
            );
          })}
        </div>
        <span className="msg-info-count">
          {i18nInstance.t("TTM.followup.messages.totalMessages", { count: count })}
        </span>
      </div>
      <img src={expandIcon} />
    </div>
  );
};

export default MessageInfoBar;
