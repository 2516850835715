import { MENUS, PATH, PERMISSIONS } from "./constants";

export interface IMenuItem {
  name: string;
  id: string;
  mdIconTxt?: string;
  mdIconCls?: string;
  link: string;
  isActive?: boolean;
  iconSrc?: string;
  relatedPaths?: string[];
  badgeCount?: number;
  linkTarget?: string;
  permission: string;
  isExternalModule?: boolean;
}

export const vehilesMenuItem: IMenuItem = {
  name: MENUS.VEHICLES,
  id: "menu1",
  mdIconTxt: "local_shipping",
  mdIconCls: "material-icons",
  isActive: true,
  link: PATH.VEHICLES,
  relatedPaths: [PATH.DISPATCH],
  permission: PERMISSIONS.FOLLOWUP,
};

export const messageCenterMenuItem: IMenuItem = {
  name: MENUS.MESSAGE_CENTER,
  id: "menu2",
  iconSrc: `${process.env.PUBLIC_URL}/assets/images/message_centre_icon.svg`,
  link: PATH.MESSAGES,
  badgeCount: 0,
  permission: PERMISSIONS.MESSAGING,
};

export const dmsMenuItem: IMenuItem = {
  name: MENUS.DMS,
  id: "menu3",
  mdIconTxt: "folder_open",
  mdIconCls: "material-icons",
  linkTarget: "_blank",
  permission: PERMISSIONS.DMS,
  isExternalModule: true,
} as IMenuItem;

export const historyMenuItem: IMenuItem = {
  name: MENUS.HISTORY,
  id: "menu4",
  iconSrc: `${process.env.PUBLIC_URL}/assets/images/history_icon.svg`,
  mdIconTxt: "history",
  link: PATH.TRACES,
  permission: PERMISSIONS.TRACES,
};

export const placesMenuItem: IMenuItem = {
  name: MENUS.PLACES,
  id: "menu5",
  mdIconTxt: "add_location",
  mdIconCls: "material-icons",
  linkTarget: "_blank",
  permission: PERMISSIONS.PLACES,
  isExternalModule: true,
} as IMenuItem;

export const menuItems: IMenuItem[] = [
  vehilesMenuItem,
  messageCenterMenuItem,
  dmsMenuItem,
  historyMenuItem,
  placesMenuItem,
];
