import React from "react";
import ReactDOM from "react-dom";
import ToastWrapper, { IToast } from "../../../application/molecules/ToastWrapper/ToastWrapper";
import "./ToastPortal.scss";
export interface IToastPortal {
  toastMsg: IToast;
  handleOnToastClose: () => void;
  selector: string;
  className: string;
}
const ToastPortal = (props: IToastPortal) => {
  return ReactDOM.createPortal(
    <div className={props.className}>
      <ToastWrapper toastProps={props.toastMsg} onClose={props.handleOnToastClose} />
    </div>,
    document.querySelector(props.selector) as Element,
  );
};
export default ToastPortal;
