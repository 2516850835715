import React from "react";

interface Props {
  taskOrder?: number;
}

const TaskInProgress = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.904" viewBox="0 0 20 19.904">
      <g>
        <ellipse cx="9.957" cy="9.952" rx="9.957" ry="9.952" fill="#fff" />
        <g>
          <path
            d="M9.954-509.737c-.657.078-.881.11-1.293.192a10.359,10.359,0,0,0-4.637,2.258A9.99,9.99,0,0,0,.6-501.1a12.217,12.217,0,0,0,0,2.7,9.862,9.862,0,0,0,3.051,5.876,10.249,10.249,0,0,0,4.869,2.575,4.277,4.277,0,0,0,.581.094.863.863,0,0,0,.813-.8.884.884,0,0,0-.444-.706,3.106,3.106,0,0,0-.5-.141,8.493,8.493,0,0,1-6.6-6.444,7.5,7.5,0,0,1-.188-1.862,7.736,7.736,0,0,1,.833-3.563,8.6,8.6,0,0,1,6.663-4.712,11.9,11.9,0,0,1,2.3.02,8.742,8.742,0,0,1,3.216,1.129l.328.192-.36.517a3.276,3.276,0,0,0-.36.6.249.249,0,0,0,.08.157c.076.067.132.067,1.53-.055.8-.071,1.5-.133,1.566-.145a.508.508,0,0,0,.368-.345,7.723,7.723,0,0,0-.38-1.556,15,15,0,0,0-.469-1.494.207.207,0,0,0-.32.016c-.044.051-.212.274-.372.5s-.3.427-.32.443-.128-.027-.244-.1a10.637,10.637,0,0,0-4-1.458A17.52,17.52,0,0,0,9.954-509.737Z"
            transform="translate(-0.525 509.75)"
            fill="#005f9e"
          />
          <path
            d="M454.566-361.844a.655.655,0,0,0-.385.646,2.475,2.475,0,0,0,.3.779.657.657,0,0,0,1.024-.05.705.705,0,0,0,.032-.722c-.145-.41-.227-.533-.422-.63A.588.588,0,0,0,454.566-361.844Z"
            transform="translate(-436.084 367.832)"
            fill="#005f9e"
          />
          <path
            d="M471.57-283.788a.773.773,0,0,0-.369.277c-.066.1-.076.151-.085.492-.013.419.016.564.142.712a.647.647,0,0,0,1.031-.057c.088-.123.1-.161.107-.454.019-.441-.028-.627-.192-.794A.626.626,0,0,0,471.57-283.788Z"
            transform="translate(-452.487 292.881)"
            fill="#005f9e"
          />
          <path
            d="M456.142-203.912c-.17.11-.218.177-.325.476-.17.47-.164.675.019.9a.643.643,0,0,0,1.056-.091,2.777,2.777,0,0,0,.23-.744.623.623,0,0,0-.656-.63A.51.51,0,0,0,456.142-203.912Z"
            transform="translate(-437.554 216.264)"
            fill="#005f9e"
          />
          <path
            d="M415.621-132.847a1.986,1.986,0,0,0-.58.652.647.647,0,0,0,.5.92c.3.028.47-.076.775-.476a.744.744,0,0,0,.211-.678.794.794,0,0,0-.362-.429A.734.734,0,0,0,415.621-132.847Z"
            transform="translate(-398.12 148.005)"
            fill="#005f9e"
          />
          <path
            d="M353.9-77.825c-.416.224-.605.394-.678.615a.645.645,0,0,0,.864.772,2.318,2.318,0,0,0,.665-.463.687.687,0,0,0,.1-.495.578.578,0,0,0-.258-.391.432.432,0,0,0-.328-.107A.536.536,0,0,0,353.9-77.825Z"
            transform="translate(-339.152 95.409)"
            fill="#005f9e"
          />
          <path
            d="M278.678-45.8a1.917,1.917,0,0,0-.325.1.733.733,0,0,0-.353.545.686.686,0,0,0,.646.678,3.991,3.991,0,0,0,.738-.17.648.648,0,0,0,.006-1.144A.968.968,0,0,0,278.678-45.8Z"
            transform="translate(-266.948 64.385)"
            fill="#005f9e"
          />
        </g>
        <text
          fill="#005f9e"
          fontSize="10"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
          textAnchor="middle"
          alignmentBaseline="central"
        >
          <tspan x="10" y="13.5">
            {props?.taskOrder}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default TaskInProgress;
