import React, { createContext, useContext, useMemo } from "react";
import { MessageStore } from "../stores/messageStore/message.store";

const MessageContext = createContext<MessageStore | null>(null);
export const useMessageStore = () => useContext(MessageContext);

export const MessageProvider = ({ children }: any) => {
  const messageStore = useMemo(() => new MessageStore(), []);
  return <MessageContext.Provider value={messageStore}>{children}</MessageContext.Provider>;
};
