import React, { useEffect, useState } from "react";
import upArrow from "../../../assets/images/arrow_up.svg";
import downArrow from "../../../assets/images/arrow_down.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import "./TaskDescription.scss";
import useMobileDetect from "../../../hooks/useMobileDetect/useMobileDetect";
import Description from "../Description/Description";
import { TEXT_PATTERN } from "../../../common/constants";
export interface ITaskDescription {
  id: string;
  isActive?: boolean;
  description: string;
  toggleTaskDescription?: (id: string) => void;
}

const TaskDescription = (props: ITaskDescription) => {
  const isMobile = useMobileDetect();
  const [isActive, setIsActive] = useState<boolean>();

  const arrowIcon = isActive ? upArrow : downArrow;

  useEffect(() => {
    setIsActive(props?.isActive);
  }, [props.isActive]);

  return (
    <div className="task-description-container">
      <div
        className="col p-0 cursor-pointer"
        onClick={() => {
          setIsActive(!isActive);
          props?.toggleTaskDescription?.(props.id);
        }}
      >
        {i18nInstance.t("TTM.followup.task.description")}
        {<img className="ml-1" src={arrowIcon} />}
      </div>
      {isActive ? (
        <div className={`task-description truncate-lines ${!isMobile ? "line-clamp-4" : ""} `}>
          <Description text={props.description} type={TEXT_PATTERN} />
        </div>
      ) : null}
    </div>
  );
};

export default TaskDescription;
