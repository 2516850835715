import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PATH } from "../common/constants";
import { traceRoutes } from "./TraceRoutes/TraceRoutes";
import { vehicleRoutes } from "./VehicleRoutes/VehicleRoutes";
import { messageRoutes } from "./MessageRoutes/MessageRoutes";
import { dispatchRoutes } from "./DispatchRoutes/DispatchRoutes";
import MasterPageTemplate from "../components/templates/MasterPageTemplate";
import CustomerSwitchTransition from "../components/pages/CustomerSwitchTransition";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";

const AppRoutes = () => {
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route element={<MasterPageTemplate data-testid="masterPageRef" />}>
          {vehicleRoutes()}
          {dispatchRoutes()}
          {messageRoutes()}
          {traceRoutes()}
        </Route>
        <Route path={`${PATH.SWITCH_CUSTOMER}`} element={<CustomerSwitchTransition />} />
        <Route path="*" element={<Navigate to={PATH.VEHICLES} replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
