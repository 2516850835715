import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MapService } from "../../../organisms/MapView/Services/MapService";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import TrimbleMaps, { LngLatBounds } from "@trimblemaps/trimblemaps-js";
import { LngLat } from "../../../../models/VehicleModel";
import { coordinateToDegree, loadScript, loadStyleSheet } from "../../../../common/utils";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { TRIMBLEMAPS_POPUP_CLOSE_BUTTON } from "../../../../common/constants";
import MapLayerStyleContainer from "../../../organisms/MapView/MapLayerStyleContainer/MapLayerStyleContainer";
export interface IMapConfig {
  apiKey: string;
  defaultMapLocation: LngLat;
  searchApi?: string;
  routeReportApi?: string;
  cdnBaseUrl: string;
  scriptFile: string;
  styleSheet: string;
  isMobile: boolean;
  locale: (typeof TrimbleMaps.Common.Language)[keyof typeof TrimbleMaps.Common.Language];
}
export interface IMapComponentProps {
  config: IMapConfig;
  resetMap: boolean;
  coordinates?: LngLat;
  filterMarkerIds?: string[];
  selectedMarkerId?: string;
  highlightMarkerIds?: string[];
  marker?: TrimbleMaps.Marker[];
  handleMapLoad?: () => void;
  handleResetMap?: () => void;
  handleMarkerPopupClose?: () => void;
}

const MapComponent = (props: IMapComponentProps) => {
  const { config } = props;
  const [lngLatBounds, setLngLatBounds] = useState<LngLatBounds>();
  const [loading, setLoading] = useState<boolean>(false);
  const [mapService, setMapService] = useState<MapService>();
  const [mapMarkers, setMapMarkers] = useState<TrimbleMaps.Marker[]>([]);
  const popupCloseBtnRef = useRef<Element | null>();
  const TRACE_MARKER_SELECTED = "trace-marker-selected";
  const TRACE_MARKER_HIGHLIGHT = "trace-marker-highlight";
  const TRACE_MARKER_SHOW = "trace-marker-fadeIn";
  const TRACE_MARKER_HIDE = "trace-marker-fadeOut";

  const mapIdleCallback = (e: any) => {
    setLngLatBounds(e?.target?.getBounds?.());
  };

  const mapLoadedCallback = (mapServiceObj: MapService) => {
    setLoading(false);
    props?.handleMapLoad?.();
    mapServiceObj?.getMap()?.on("idle", mapIdleCallback);
    mapServiceObj?.getMap()?.addControl(new TrimbleMaps.FullscreenControl());
  };
  const destroyPopupCloseEventListener = () => {
    props?.handleMarkerPopupClose &&
      popupCloseBtnRef?.current?.removeEventListener("click", props?.handleMarkerPopupClose);
    popupCloseBtnRef.current = null;
  };

  const initMapService = () => {
    try {
      const mapService = new MapService();
      mapService.initMap({
        apiKey: config.apiKey,
        container: "map",
        region: TrimbleMaps.Common.Region.EU,
        language: config.locale || TrimbleMaps.Common.Language.EN,
        center: new TrimbleMaps.LngLat(
          coordinateToDegree(config.defaultMapLocation.lon),
          coordinateToDegree(config.defaultMapLocation.lat),
        ),
        isMobile: config.isMobile,
      });
      setMapService(mapService);
      mapService.getMap()?.on("load", () => mapLoadedCallback(mapService));
      mapService.getMap()?.on("click", () => destroyPopupCloseEventListener());
    } catch (error) {
      console.log("initMapService ~ error:", error);
    }
  };

  const getLatLonBounds = (markers: TrimbleMaps.Marker[]) => {
    const lngLatBounds = new TrimbleMaps.LngLatBounds();
    markers.forEach((m) => lngLatBounds.extend(m?.getLngLat?.()));
    return lngLatBounds;
  };

  const loadMapScript = async () => {
    try {
      await loadScript(`${config.cdnBaseUrl}/${config.scriptFile}`, "map-JS");
    } catch (error) {
      console.log("Error in loading CDN map library reference", error);
      await loadScript(`${process.env.PUBLIC_URL}/assets/js/${config.scriptFile}`, "map-JS");
    }
  };

  const loadMapStyle = async () => {
    try {
      await loadStyleSheet(`${config.cdnBaseUrl}/${config.styleSheet}`, "map-CSS");
    } catch (error) {
      console.log("Error in loading CDN map style reference", error);
      await loadStyleSheet(`${process.env.PUBLIC_URL}/assets/css/${config.styleSheet}`, "map-CSS");
    }
  };
  const handleSelectedMarker = () => {
    try {
      if (mapService && props?.coordinates && props?.selectedMarkerId) {
        const coordinates = new TrimbleMaps.LngLat(
          coordinateToDegree(props?.coordinates.lon),
          coordinateToDegree(props?.coordinates.lat),
        );

        mapService.highlightSelectedMarker(props.selectedMarkerId, coordinates);
      }
    } catch (error) {
      console.log("handleSelectedMarker ~ error:", error);
    }
  };
  useEffect(() => {
    if (props?.marker && props?.marker?.length > 0) {
      mapService?.drawMarkers(props.marker);
      setMapMarkers([...mapMarkers, ...props.marker]);
    }
  }, [props?.marker]);

  useEffect(() => {
    // !TODO: Explore options to optimise getting lat lon bounds.
    mapMarkers.length > 0 && mapService?.fitBounds(getLatLonBounds(mapMarkers));
  }, [mapMarkers]);

  useEffect(() => {
    try {
      mapService?.removeMarkerHighlights(TRACE_MARKER_SELECTED);
      mapService?.removeMarkerHighlight();
      if (props?.selectedMarkerId && props?.handleMarkerPopupClose) {
        mapService?.openPopup(mapMarkers, props.selectedMarkerId);
        mapService?.highlightTraceMarker([props.selectedMarkerId], TRACE_MARKER_SELECTED);
        if (config.isMobile && props.coordinates) {
          handleSelectedMarker();
        }
        popupCloseBtnRef.current = document?.getElementsByClassName(
          TRIMBLEMAPS_POPUP_CLOSE_BUTTON,
        )?.[0];
        popupCloseBtnRef?.current?.addEventListener("click", props?.handleMarkerPopupClose, {
          once: true,
        });
      }
    } catch (error) {
      console.log("MapComponent ~ marker selection ~ error:", error);
    }
  }, [props?.selectedMarkerId]);

  useEffect(() => {
    mapService?.removeMarkerHighlights(TRACE_MARKER_HIGHLIGHT);
    if (props?.highlightMarkerIds) {
      mapService?.highlightTraceMarker(props.highlightMarkerIds, TRACE_MARKER_HIGHLIGHT);
    }
  }, [props?.highlightMarkerIds]);

  useEffect(() => {
    try {
      mapMarkers?.forEach((marker) => {
        props?.filterMarkerIds &&
          mapService?.toggleMarkerVisibility(marker, props.filterMarkerIds, {
            showClass: TRACE_MARKER_SHOW,
            hideClass: TRACE_MARKER_HIDE,
          });
      });
    } catch (error) {
      console.log("filterMarkerIds ~ error:", error);
    }
  }, [props?.filterMarkerIds]);

  useEffect(() => {
    if (props?.resetMap) {
      mapService?.resetMap(mapMarkers);
      setMapMarkers([]);
      props?.handleResetMap?.();
    }
  }, [props?.resetMap]);

  useEffect(() => {
    if (mapService && config.locale) {
      mapService.setLanguage(config.locale);
    }
  }, [config.locale]);

  const init = async () => {
    try {
      if (!mapService) {
        setLoading(true);
        await loadMapScript();
        loadMapStyle();
        initMapService();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("map component ~ init ~ error:", error);
    }
  };

  // Initialize useEffect.
  useLayoutEffect(() => {
    init();
    return () => {
      try {
        mapService?.getMap()?.off("load", mapLoadedCallback);
        mapService?.getMap()?.off("click", () => destroyPopupCloseEventListener());
        destroyPopupCloseEventListener();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <div id="map" className="map-container" style={{ width: "100%", height: "100%" }}>
      {loading && (
        <div className="loader-container">
          <div>
            <Preloader />
          </div>
          <div className="loader-message">{i18nInstance.t("TTM.followup.map.loading")}</div>
        </div>
      )}
      {!loading && mapService && <MapLayerStyleContainer mapService={mapService} />}
    </div>
  );
};

export default MapComponent;
