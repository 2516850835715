import React from "react";
import "./Description.scss";
import { Tooltip } from "@trimbleinc/modus-react-bootstrap";
import OverlayTrigger from "@trimbleinc/modus-react-bootstrap/OverlayTrigger";
import { getFormattedText } from "../../../common/formattingUtils";
import { ITextFormat } from "../../../common/models";

/* Breaks the string into array of string matching the style pattern.
    input text: This is a [sample|style://color:#007575] text.
    output array: ["This is a [sample|style://color:#007575], "This is a", "sample", "color:#007575"]"
*/
export const DESCRIPTION_STYLE_PATTERN = /(.*?)\[([^\\|]+)\|style:\/\/(.*?)\]/;

/* Function to iterator the matched style and outputs the textStyle and textColor
    input: color:#007575;bold
    output: {textColor : "#007575", textStyle: "description-bold"}
*/

const Description = (props: ITextFormat) => {
  const getTaskDescriptionStyles = (style: string) => {
    const splittedStyles = style?.split(";");
    let textColor = "";
    let textStyle = "";
    splittedStyles.forEach((s) =>
      s.includes(":") ? (textColor = s.split(":")[1]) : (textStyle += ` description-${s}`),
    );
    return { textStyle, textColor };
  };

  const getFormattedDescription = (text: string) => {
    const groups = getFormattedText(text, DESCRIPTION_STYLE_PATTERN, props.type);
    if (groups && groups?.length > 0) {
      return groups?.map((item, index) => {
        if (typeof item == "string") {
          return item;
        } else {
          const styles = getTaskDescriptionStyles(item?.[3]);
          return (
            <span key={`${item?.[2] + index}`}>
              {item?.[1]}
              <span className={styles.textStyle} style={{ color: styles?.textColor }}>
                {item?.[2]}
              </span>
            </span>
          );
        }
      });
    } else {
      return text;
    }
  };

  const descriptionRenderer = (text: string) => {
    return text
      .replace(/\r/g, "\n")
      .split("\n")
      .map((text, index) => (
        <div key={`${text + index}`} className="description">
          {getFormattedDescription(text)}
        </div>
      ));
  };
  const formattedDescription = descriptionRenderer(props.text);
  return (
    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id="descriptionTooltip">{formattedDescription}</Tooltip>}
    >
      <span>{formattedDescription}</span>
    </OverlayTrigger>
  );
};

export default Description;
