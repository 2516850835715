import React from "react";
import "./VehicleSpeed.scss";
import speedIcon from "../../../../../assets/images/speed.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { MEASUREMENT_UNITS } from "../../../../../common/constants";

interface Props {
  speed: number;
}

const VehicleSpeed = (props: Props) => {
  const { speed } = props;

  return (
    <div className="speed speed-container">
      <img src={speedIcon} title={i18nInstance.t("TTM.followup.speed")}></img>
      <span className="speed-value">
        {speed} {MEASUREMENT_UNITS.SPEED}
      </span>
    </div>
  );
};

export default VehicleSpeed;
