import React, { useEffect, useRef, useState } from "react";
import "./NewMessage.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import useOnOutsideClick from "@utilityjs/use-on-outside-click/useOnOutsideClick";
import FormCheckbox from "../../atoms/FormCheckbox/FormCheckbox";
import FormDropdown, { IFormDropdownOption } from "../FormDropdown/FormDropdown";
import FormTextarea from "../../atoms/FormTextarea/FormTextarea";
import { ReactComponent as SendIcon } from "../../../assets/images/message/msg_send.svg";
import { useMessageStore } from "../../../contexts/message.context";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import { IMessageError, INewSendMessage } from "../../../models/MessageModel";
import { useKeyPress } from "../../../hooks/useKeyPress/useKeyPress";
import FileInput from "../../global/molecules/FileInput/FileInput";
import ToastWrapper, {
  IToast,
  defaultToastObj,
} from "../../application/molecules/ToastWrapper/ToastWrapper";
import { TOAST_STYLE } from "../../../common/constants";
import { getAppConfig, isElementInViewport, sendMonitoringLogs } from "../../../common/utils";
import FileAttachment from "../../application/molecules/FileThumbnail/FileThumbnail";

export interface INewMessage {
  id: string;
  lastMsgId?: string;
  showLoader: boolean;
  subject?: string;
  msgError?: IMessageError;
  activeThreadId: string;
  isSubjectEmpty?: boolean;
  setSubjectText?: () => void;
  onSendMessage: (newMsg: INewSendMessage) => void;
  setActiveThreadId?: (id: string) => void;
}

const ALLOWED_FILE_FORMATS = "image/png,image/jpeg,application/pdf";

const NewMessage = (props: INewMessage) => {
  const {
    id,
    showLoader,
    activeThreadId,
    msgError,
    lastMsgId,
    subject,
    isSubjectEmpty,
    onSendMessage,
    setActiveThreadId,
  } = props;
  const newMessageRef = useRef<HTMLDivElement>(null);
  const attachmentRef = useRef<HTMLDivElement>(null);
  const messageStore = useMessageStore();

  // messageAttachment flag.
  const showAttachFile = getAppConfig().messageAttachment;

  const [messageText, setMessageText] = useState<string>("");
  const [answerRequired, setAnswerRequired] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<string>("");

  const [focusBlur, setFocusBlur] = useState<boolean>(true);
  const [showMsgOptions, setShowMsgOptions] = useState<boolean>(false);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [isFileLoading, setFileLoading] = useState<boolean>(false);
  const [toastObj, setToastObj] = useState<IToast>(defaultToastObj);

  const newMsgOptionsCls = showMsgOptions ? "new-msg-options-active" : "new-msg-options-inactive";
  const isFormValid =
    activeThreadId === id && messageText.trim().length > 0 && showMsgOptions && !isSubjectEmpty;

  useOnOutsideClick(newMessageRef, () => {
    if (answerRequired || messageType.length > 0) {
      setShowMsgOptions(true);
    } else {
      setShowMsgOptions(false);
    }
  });

  /**
   * Function to frame the new message object and trigger the handler in the parent.
   */
  const sendMessage = () => {
    const newMessageObj: INewSendMessage = {};
    newMessageObj.answerRequired = answerRequired;
    messageText && messageText.length > 0 && (newMessageObj.body = messageText.trim());
    messageType && messageType.length > 0 && (newMessageObj.messageType = messageType);
    subject && subject.length > 0 && (newMessageObj.subject = subject);
    lastMsgId && (newMessageObj.inReplyTo = lastMsgId);
    selectedFile && (newMessageObj.file = selectedFile);
    onSendMessage(newMessageObj);
  };

  const handleOnEnter = (event: any) => {
    if (event?.target?.id == id) {
      event.preventDefault();
      isFormValid && sendMessage();
    }
  };

  const handleOnEscape = (event: any) => {
    if (event?.target?.id == id) {
      event.preventDefault();
      setActiveThreadId?.("");
      setShowMsgOptions(false);
      setFocusBlur(true);
    }
  };

  useKeyPress(newMessageRef, "Enter", handleOnEnter);
  useKeyPress(newMessageRef, "Escape", handleOnEscape);

  const handleOnSend = () => {
    if (isFormValid) {
      sendMessage();
      sendMonitoringLogs("MESSAGE_CONVERSATION_SEND_MESSAGE");
    } else {
      setActiveThreadId?.(id);
      setShowMsgOptions(true);
    }
  };

  const handleOnToastClose = () => setToastObj({ ...toastObj, showToast: false });

  //----------------------- ATTACHMENT HANDLERS -----------------------//

  const handleOnFileSelect = (file: File) => {
    setSelectedFile(file);
    setFileLoading(false);
  };

  const handleOnClear = () => {
    setSelectedFile(undefined);
    setFileLoading(false);
  };

  const handleOnFileLoadStart = () => {
    setSelectedFile(undefined);
    setFileLoading(true);
  };

  const handleOnFileError = (error: string) => {
    setToastObj({ showToast: true, message: error, style: TOAST_STYLE.ERROR });
    handleOnClear();
  };

  const handleMessageTypeSelection = (option: IFormDropdownOption) => {
    setMessageType(option.id);
    sendMonitoringLogs("MESSAGE_CONVERSATION_SELECT_MESSAGE_TYPE");
  };

  //--------------- USEEFFECTS ---------------//

  useEffect(() => {
    if (msgError && msgError.error && activeThreadId === id) {
      setToastObj({
        showToast: true,
        message: msgError.message || i18nInstance.t("TTM.followup.generic.error"),
        style: TOAST_STYLE.ERROR,
      });
    }
    if (msgError !== undefined && !msgError.error) {
      setMessageText("");
      setAnswerRequired(false);
      setMessageType("");
      setSelectedFile(undefined);
    }
  }, [msgError]);

  //UseEffect to scroll to the attachment container if not visible in the viewport.
  useEffect(() => {
    try {
      if (attachmentRef?.current && !isElementInViewport(attachmentRef.current)) {
        attachmentRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    } catch (error) {
      console.log("Error while scrolling", error);
    }
  }, [selectedFile]);

  //--------------------------------------------------------//

  return (
    <div
      className={`new-msg-container ${
        selectedFile !== undefined || isFileLoading ? "form-input-attachment" : ""
      }`}
      ref={newMessageRef}
    >
      <FormTextarea
        id={id}
        placeholder={i18nInstance.t("TTM.followup.messages.messagePlaceHolder")}
        inputValue={messageText}
        inputFocusBlur={focusBlur}
        onFocus={() => {
          setShowMsgOptions(true);
          setFocusBlur(false);
          setActiveThreadId?.(id);
        }}
        onChange={(text) => setMessageText(text)}
      >
        <div
          className={`icon-container ${selectedFile ? "icon-container-attachment" : ""}`}
          title={i18nInstance.t("TTM.followup.send")}
        >
          {showAttachFile && (
            <div
              className={`icon icon-${selectedFile ? "enabled" : "disabled"} ${
                showLoader && "icon-loading"
              }`}
            >
              <FileInput
                id={id}
                maxSize={messageStore?.maxFileSize}
                fileFormats={ALLOWED_FILE_FORMATS}
                onFileSelect={(file) => handleOnFileSelect(file)}
                onFileError={(error) => handleOnFileError(error)}
                onFileLoadStart={handleOnFileLoadStart}
              />
            </div>
          )}
          {showLoader && activeThreadId === id ? (
            <div className="icon new-msg-preloader pl-1">
              <Preloader />
            </div>
          ) : (
            <SendIcon
              className={`icon send-icon icon-${isFormValid ? "enabled" : "disabled"}`}
              onClick={handleOnSend}
            />
          )}
        </div>
      </FormTextarea>
      <div className="msg-toast">
        <ToastWrapper toastProps={toastObj} onClose={handleOnToastClose} />
      </div>
      {(isFileLoading || selectedFile) && (
        <div className="attachment-container" ref={attachmentRef}>
          <FileAttachment file={selectedFile} isLoading={isFileLoading} onClear={handleOnClear} />
        </div>
      )}

      <div className={`new-msg-options ${newMsgOptionsCls}`}>
        <div className="new-msg-checkbox">
          <FormCheckbox
            id={id}
            isChecked={answerRequired}
            label={i18nInstance.t("TTM.followup.messages.answerRequired")}
            onChange={(flag: boolean) => {
              setAnswerRequired(flag);
              sendMonitoringLogs("MESSAGE_CONVERSATION_ANSWER_REQUIRED");
            }}
          />
        </div>
        {messageStore?.messageTypes && messageStore.messageTypes.length > 0 && (
          <div className="new-msg-dropdown">
            <FormDropdown
              id={id}
              showClearIcon
              title={i18nInstance.t("TTM.followup.messages.messageType")}
              onSelectOption={(option: IFormDropdownOption) => handleMessageTypeSelection(option)}
              selected={messageType}
              options={messageStore.messageTypes}
              onClear={() => setMessageType("")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewMessage;
