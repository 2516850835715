import React from "react";

interface Props {
  taskOrder?: number;
}

const TaskComplete = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(-0.047 -1.141)">
        <g transform="translate(0.047 1.141)" fill="#fff" stroke="#4a821f" strokeWidth="1.5">
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.25" fill="none" />
        </g>
        <text
          fill="#4a821f"
          fontSize="10"
          fontFamily="OpenSans-Bold, Open Sans"
          fontWeight="700"
          textAnchor="middle"
          alignmentBaseline="central"
        >
          <tspan x="10" y="14.5">
            {props?.taskOrder}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default TaskComplete;
