import React from "react";
import { getFormattedText } from "../../../../common/formattingUtils";
import DocumentLink from "../DocumentLink/DocumentLink";
import { ITextFormat } from "../../../../common/models";

/* Breaks the string into array of string matching the style pattern.
    input text: Photo taken [dl link=document_link]document_name[/dl] message
    output array: ["Photo taken [dl link=document_link]document_name[/dl]", "Photo taken", "[dl link=document_link]document_name[/dl]", "document_link", "document_name"]"
*/
const DOCUMENT_NAME_PATTERN = /((.*?)(\[dl link=(.*?)\](.*?)\[\/dl\]))/;

const DocumentNameFormatter = (props: ITextFormat) => {
  const formattedAttachment = (text: string) => {
    const groups = getFormattedText(text, DOCUMENT_NAME_PATTERN, props.type);
    if (groups && groups.length > 0) {
      return groups?.map((item, index) => {
        if (typeof item == "string") {
          return item;
        } else {
          return (
            <span key={`${item?.[5] + index}`}>
              {item?.[2]}
              <DocumentLink
                name={item?.[5]}
                fetchDownloadUrl={`${props.linkPrefix}${item?.[5]}`}
                isDownload={props?.isDownload}
              />
            </span>
          );
        }
      });
    } else {
      return text;
    }
  };
  const messageRenderer = (text: string) => {
    if (text) {
      return text
        .replace(/\r/g, "\n")
        .split("\n")
        .map((text, index) => (
          <div key={`${text + index}`} className={`${index === 0 && "attachment-received"}`}>
            {formattedAttachment(text)}
          </div>
        ));
    }
  };

  const formattedMessage = messageRenderer(props.text);
  return <span className="w-100">{formattedMessage}</span>;
};

export default DocumentNameFormatter;
