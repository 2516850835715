import React, { useEffect, useRef, useState } from "react";
import { ITraceOutletContext } from "../../../../../models/TraceModel";
import { useOutletContext } from "react-router-dom";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import TraceDetails from "../../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { find } from "lodash";
import { useVirtualizer } from "@tanstack/react-virtual";
import "./TracesListPanel.scss";
import DateTime from "../../../../molecules/DateTime/DateTime";
import up from "../../../../../assets/images/arrow_up.svg";
import SkeletonComponent from "../../../../atoms/Skeleton/SkeletonComponent";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import errorIcon from "../../../../../assets/images/error_icon.svg";
import infoIcon from "../../../../../assets/images/info.svg";
import { DATE_TIME_FORMAT } from "../../../../../common/constants";
import { sendMonitoringLogs } from "../../../../../common/utils";
import TraceTypeSearch from "../TraceTypeSearch/TraceTypeSearch";
import { useDidMountEffect } from "../../../../../hooks/useDidMountEffect/useDidMountEffect";

const TracesListPanel = () => {
  const traceStore = useTraceStore();
  const [activeTraceIds, setActiveTraceIds] = useState<string[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);
  const traceContext = useOutletContext<ITraceOutletContext>();

  const virtualizer = useVirtualizer({
    count: traceContext?.tracesList?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 80,
    overscan: 5,
  });

  const items = virtualizer?.getVirtualItems();

  const scrollToTrace = (traceId: string | number) => {
    const index = traceContext?.tracesList?.findIndex((trace) => trace.id == traceId);
    if (index >= 0 && parentRef && parentRef.current) {
      virtualizer.scrollToIndex(index, { align: "center" });
    }
  };

  const scrollToTop = () => {
    try {
      virtualizer?.scrollToIndex?.(0);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const getTraceName = (index: number) => {
    return find(traceStore?.traceTypes, {
      traceType: traceContext?.tracesList[index]?.type,
    })?.name;
  };

  useEffect(() => {
    if (traceContext?.resetTraces) {
      setActiveTraceIds([]);
    }
  }, [traceContext?.resetTraces]);

  useDidMountEffect(() => {
    if (traceContext?.isDataLoaded && traceContext?.tracesList?.length > 0) {
      scrollToTop();
    }
  }, [traceContext?.isDataLoaded]);

  /**
   * This block scrolls to selected trace, but only if the data is loaded and there are traces available.
   */
  useEffect(() => {
    try {
      if (traceContext?.isDataLoaded && traceContext?.tracesList?.length > 0) {
        const traceIdToScroll = traceContext?.selectedTrace?.id;
        traceIdToScroll && scrollToTrace(traceIdToScroll);
      }
    } catch (error) {
      console.log(" error:", error);
    }
  }, [traceContext?.selectedTrace, traceContext?.isDataLoaded]);

  const handleOnClick = (selectedId: string) => {
    const isActive = activeTraceIds.includes(selectedId);
    isActive
      ? setActiveTraceIds(activeTraceIds.filter((activeId) => activeId !== selectedId))
      : setActiveTraceIds([...activeTraceIds, selectedId]);
    sendMonitoringLogs(
      "HISTORY_TRACESLIST_TRACE_TOGGLE_ACCORDION",
      isActive ? "COLLAPSE" : "EXPAND",
    );
  };

  return (
    <div className="block-container flex-column traces-list">
      <div className="trace-types-container">
        <TraceTypeSearch />
      </div>
      {traceContext?.error && (
        <div className="block-container d-flex flex-column justify-content-center">
          <div className="m-2 p-3 trace-error">
            <img className="trace-error-icon" src={errorIcon} />
            {`${i18nInstance.t("TTM.followup.generic.error")}`}
          </div>
        </div>
      )}
      {!traceContext?.error && !traceContext?.isDataLoaded && (
        <div className="m-2">
          <SkeletonComponent count={2} height={35} width={"100%"} />
        </div>
      )}
      {!traceContext?.error && traceContext?.isDataLoaded && (
        <div
          className="block-container d-flex flex-column justify-content-center overflow-auto"
          onMouseLeave={() => traceContext?.handleHighlightedTraces([])}
        >
          {traceContext?.tracesList?.length > 0 ? (
            <div ref={parentRef} className="traces-list-container">
              <div
                style={{
                  position: "relative",
                  height: virtualizer?.getTotalSize(),
                }}
              >
                <div
                  className="traces-list-panel"
                  style={{
                    transform: `translateY(${items?.[0]?.start ?? 0}px)`,
                  }}
                >
                  {items?.map((virtualRow) => (
                    <div
                      className={`traces-list-row cursor-pointer ${
                        traceContext?.selectedTrace?.id ==
                        traceContext?.tracesList?.[virtualRow.index]?.id
                          ? "traces-list-row-selected"
                          : ""
                      } ${
                        traceContext?.highlightedTraceIds?.includes(
                          traceContext?.tracesList?.[virtualRow.index]?.id.toString(),
                        )
                          ? "traces-list-row-highlight"
                          : ""
                      }`}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={(el) => virtualizer.measureElement(el)}
                      onMouseOver={() => {
                        traceContext?.handleHighlightedTraces([
                          traceContext?.tracesList?.[virtualRow.index].id.toString(),
                        ]);
                      }}
                    >
                      <div className="traces-list-item">
                        <div
                          className="traces-list-info"
                          onClick={() => {
                            traceContext?.handleSelectedTrace(
                              traceContext?.tracesList?.[virtualRow.index],
                            );
                            sendMonitoringLogs("HISTORY_TRACESLIST_TRACE_SELECT");
                          }}
                        >
                          <span
                            className="trace-title truncate-text"
                            title={getTraceName(virtualRow.index)}
                          >
                            {getTraceName(virtualRow.index)}
                          </span>
                          <span className="trace-timestamp">
                            <DateTime
                              dateTime={traceContext?.tracesList?.[virtualRow.index]?.timestamp}
                              displayFormat={DATE_TIME_FORMAT}
                            />
                          </span>
                        </div>
                        <div
                          className="trace-accordion-arrow"
                          onClick={() =>
                            handleOnClick(traceContext?.tracesList?.[virtualRow.index]?.id)
                          }
                        >
                          <img
                            className={`${
                              activeTraceIds.includes(
                                traceContext?.tracesList?.[virtualRow.index]?.id,
                              )
                                ? "trace-accordion-arrow-up"
                                : "trace-accordion-arrow-down"
                            }`}
                            src={up}
                          />
                        </div>
                      </div>

                      {activeTraceIds.includes(
                        traceContext?.tracesList?.[virtualRow.index]?.id,
                      ) && (
                        <div>
                          <TraceDetails
                            trace={traceContext?.tracesList?.[virtualRow.index]}
                            showTraceProperties={true}
                            terminalId={traceContext?.terminalId}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center trace-error">
              <img className="trace-error-icon" src={infoIcon} />
              {`${i18nInstance.t("TTM.followup.error.noDataAvailable")}`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(TracesListPanel);
