import React from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { IRoute, ITask, ITrip } from "../../../models/VehicleDetailsModel";
import TaskView from "../TaskView/TaskView";
import Accordion from "../Accordion/Accordion";

export interface ITripProps {
  data: ITrip[];
  vehicleId: string;
  selectedTask?: ITask | null;
  selectedRoute?: IRoute | null;
  activeTripTaskId?: string;
  setSelectedRoute?: (route?: IRoute) => void;
  activeDescriptionIds: string[];
  toggleTaskDescription?: (id: string) => void;
  actionName?: string;
}

const TripView = (props: ITripProps) => {
  return (
    <>
      {props.data &&
        props.data.length > 0 &&
        props.data.map((trip: ITrip, index: number) => (
          <Accordion
            id={trip.id}
            key={index}
            tripTaskStatus={trip.status}
            title={
              trip?.tasks?.length > 0
                ? `${trip?.name} - ${`${trip?.tasks?.length} ${i18nInstance.t("TTM.followup.task", {
                    count: trip?.tasks?.length,
                  })}`} `
                : trip?.name
            }
            isActive={
              trip.id === props?.activeTripTaskId ||
              trip?.tasks?.find((t: ITask) => t.id === props?.activeTripTaskId)
                ? true
                : false
            }
            selectedTask={props.selectedTask}
            tasks={trip?.tasks}
            selectedRoute={props.selectedRoute}
            setSelectedRoute={props.setSelectedRoute}
            actionName={props.actionName}
          >
            <TaskView
              data={trip?.tasks}
              activeDescriptionIds={props.activeDescriptionIds}
              toggleTaskDescription={props.toggleTaskDescription}
            />
          </Accordion>
        ))}
    </>
  );
};

export default TripView;
