import React, { useEffect, useState } from "react";
import "./Accordion.scss";
import { getRouteStops, sendMonitoringLogs } from "../../../common/utils";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import upArrow from "../../../assets/images/keyboard_arrow_up.svg";
import { IRoute, ITask } from "../../../models/VehicleDetailsModel";
import downArrow from "../../../assets/images/keyboard_arrow_down.svg";
import { ReactComponent as Route } from "../../../assets/images/route.svg";
import { useVehicleStore } from "../../../contexts/vehicles.context";
import { observer } from "mobx-react-lite";
import completedIcon from "../../../assets/images/completed_icon.svg";
import { STATE } from "../../../common/constants";
export interface IAccordionProps {
  id?: string;
  title: string;
  children: React.ReactNode;
  isActive: boolean;
  tasks?: ITask[];
  selectedTask?: ITask | null;
  selectedRoute?: IRoute | null;
  setSelectedRoute?: (route?: IRoute) => void;
  tripTaskStatus?: number;
  actionName?: string;
}

const Accordion = observer((props: IAccordionProps) => {
  const vehiclesStore = useVehicleStore();
  const [isActive, setIsActive] = useState<boolean>();
  const [isRouteActive, setIsRouteActive] = useState<boolean>(false);
  const handleRouteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const showRoute = props?.selectedRoute?.id !== props.id;
    setIsRouteActive(showRoute);
    vehiclesStore?.setSelectedTask?.(null);
    if (showRoute) {
      props?.setSelectedRoute?.({ id: props?.id, tasks: props?.tasks } as IRoute);
    } else {
      props?.setSelectedRoute?.();
    }
    sendMonitoringLogs("VEHICLE_TRIP_ROUTE");
  };
  useEffect(() => {
    const isPresent = props?.tasks?.find((t) => t?.id === props.selectedTask?.id);
    setIsRouteActive(false);
    if (props.id === props?.selectedRoute?.id) {
      setIsRouteActive(true);
    }
    if (isPresent && props.tasks) {
      props?.setSelectedRoute?.({ id: props?.id, tasks: props?.tasks } as IRoute);
    }
  }, [props.selectedTask, props.selectedRoute]);

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  const arrowIcon = isActive ? (
    <img src={upArrow} title={i18nInstance.t("TTM.followup.collapse")} />
  ) : (
    <img src={downArrow} title={i18nInstance.t("TTM.followup.expand")} />
  );
  const getTripStyle = (status: number) => {
    if (status === STATE.BUSY) {
      return "ongoing-trip";
    } else if (status === STATE.FINISHED) {
      return "finished-trip";
    } else {
      return "open-trip";
    }
  };
  return (
    <div className="accordion-trip-item">
      <div
        className={`accordion-trip-title ${
          props?.tripTaskStatus ? getTripStyle(props.tripTaskStatus) : ""
        } ${isActive ? " active" : ""}`}
        onClick={() => {
          setIsActive(!isActive);
          props?.actionName &&
            sendMonitoringLogs(props?.actionName, isActive ? "COLLAPSE" : "EXPAND");
        }}
      >
        <div className="title d-flex">
          {props?.tripTaskStatus === STATE.FINISHED && (
            <div className="completed-icon">
              <img src={completedIcon} />
            </div>
          )}
          <span>{props.title}</span>
        </div>
        {props.tasks && props.tasks?.length > 0 && (
          <div className="accordion-action-items">
            {isActive && getRouteStops(props.tasks).length >= 2 && (
              <Route
                className={`trip-icon ${isRouteActive ? "active" : "inactive"} `}
                title={
                  isRouteActive
                    ? i18nInstance.t("TTM.followup.hideRoute")
                    : i18nInstance.t("TTM.followup.showRoute")
                }
                onClick={(e: React.MouseEvent) => handleRouteClick(e)}
              />
            )}
            <div>{arrowIcon}</div>
          </div>
        )}
      </div>
      {isActive && <div>{props.children}</div>}
    </div>
  );
});

export default Accordion;
