import React from "react";
import { Navigate } from "react-router-dom";
import { useAppStore } from "../../contexts/app.context";

export interface IProtectedRoute {
  permission: string;
  redirectPath?: string;
  children: React.ReactNode;
}

const ProtectedRoute = (props: IProtectedRoute) => {
  const appStore = useAppStore();
  const isAuthorized = appStore?.permissions?.includes(props.permission);
  return isAuthorized ? <>{props.children}</> : <Navigate to={props?.redirectPath || "/"} />;
};

export default ProtectedRoute;
