import React, { createContext, useContext, useMemo } from "react";
import { VehicleStore } from "../stores/vehicleStore/vehicles.store";

const DispatchContext = createContext<VehicleStore | null>(null);
export const useDispatchStore = () => useContext(DispatchContext);

export const DispatchProvider = ({ children }: any) => {
  const vehiclesStore = useMemo(() => new VehicleStore(), []);
  return <DispatchContext.Provider value={vehiclesStore}>{children}</DispatchContext.Provider>;
};
export default DispatchContext;
