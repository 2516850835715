import React from "react";
import { ITask } from "../../../models/VehicleDetailsModel";
import TaskDetails from "../TaskDetails/TaskDetails";

export interface ITaskProps {
  data: ITask[];
  vehicleId?: string;
  activeDescriptionIds: string[];
  toggleTaskDescription?: (id: string) => void;
}
const TaskView = (props: ITaskProps) => {
  return (
    <>
      {props.data &&
        props.data.length > 0 &&
        props.data.map((task: ITask, index: number) => (
          <TaskDetails
            order={index + 1}
            task={task}
            key={task.id}
            nextTask={index < props.data.length - 1}
            isTaskDescriptionActive={props.activeDescriptionIds.includes(task.id)}
            toggleTaskDescription={props.toggleTaskDescription}
          />
        ))}
    </>
  );
};
export default TaskView;
