import React from "react";
import "./CustomDatePicker.scss";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/arrow_fill_back.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/arrow_fill_forward.svg";
import { DATE_FORMAT } from "../../../../common/constants";
import DatePicker from "../../../global/molecules/DatePicker/Datepicker";
import moment, { Moment } from "moment";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export interface ICustomDatePicker {
  selectedDate?: Moment;
  onDateSelect?: (date: Date | null) => void;
}

const CustomDatePicker = (props: ICustomDatePicker) => {
  const { selectedDate, onDateSelect } = props;

  const isFutureDate = selectedDate && !moment(selectedDate).isBefore(moment(), "day");
  const formattedSelectedDate = selectedDate?.format(DATE_FORMAT);

  const handleOnPreviousDateSelect = () => {
    const prevDate = selectedDate?.subtract(1, "day");
    prevDate && onDateSelect?.(prevDate.toDate());
  };

  const handleOnNextDateSelect = () => {
    const nextDate = selectedDate?.add(1, "day");
    nextDate && onDateSelect?.(nextDate.toDate());
  };

  return (
    <div className="flex-center">
      {/* previous date select*/}
      <div
        className="arrow-icon flex-center"
        onClick={handleOnPreviousDateSelect}
        title={i18nInstance.t("TTM.followup.datePicker.previousDate")}
      >
        <ArrowLeft />
      </div>

      <div className="custom-datepicker">
        <DatePicker
          maxDate={new Date()}
          selectedDate={selectedDate?.toDate()}
          showTimeSelect={false}
          showTodayBtn={true}
          showClearBtn={false}
          onDateSelect={onDateSelect}
        >
          <div className="flex-center">
            <CalendarIcon className="calendar-icon" />
            <span className="custom-datepicker-text truncate-text" title={formattedSelectedDate}>
              {formattedSelectedDate}
            </span>
          </div>
        </DatePicker>
      </div>

      {/* next date select*/}
      <div
        className={`arrow-icon flex-center ${isFutureDate && "arrow-disable"}`}
        onClick={() => !isFutureDate && handleOnNextDateSelect()}
        title={i18nInstance.t("TTM.followup.datePicker.nextDate")}
      >
        <ArrowRight />
      </div>
    </div>
  );
};

export default CustomDatePicker;
