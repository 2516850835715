import React from "react";
import { Navigate, Route } from "react-router-dom";
import { PATH, PERMISSIONS } from "../../common/constants";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Details from "../../components/templates/Details/Details";
import { DispatchProvider } from "../../contexts/dispatch.context";

const DispatchModule = React.lazy(() => import("../../components/pages/Dispatch/Dispatch"));

export const dispatchRoutes = () => {
  return (
    <Route
      path={`${PATH.DISPATCH}`}
      element={
        <ProtectedRoute permission={PERMISSIONS.FOLLOWUP}>
          <DispatchProvider>
            <DispatchModule />
          </DispatchProvider>
        </ProtectedRoute>
      }
    >
      {/* Nested route path to handle the vehicle details panel */}
      <Route path={`${PATH.DISPATCH}/:vehicleId`} element={<Details />} />
    </Route>
  );
};
