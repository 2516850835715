import React from "react";
import "./RefreshMessageView.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import RefreshButton from "../../molecules/RefreshButton/RefreshButton";
import errorIcon from "../../../assets/images/error_icon.svg";

export interface IRefreshMessageViewProps {
  onRefresh: () => void;
  errorMessage: string;
}

const RefreshMessageView = (props: IRefreshMessageViewProps) => {
  return (
    <div className="refresh-info">
      <div className="d-flex flex-row">
        <img className="error-icon mr-2" src={errorIcon} />
        <span className="font-weight-bold">{props.errorMessage}</span>
      </div>
      <div>{i18nInstance.t("TTM.followup.error.retry")}</div>
      <RefreshButton onRefresh={props.onRefresh}/>
    </div> 
  )
};
export default RefreshMessageView;