import { DependencyList, useEffect, useRef } from "react";
/**
 * Custom hook to listen for changes once after component is rendered and avoid calling for the very first time during component initialization
 * @param func - wrapper function that need to be called during deps changes
 * @param deps - array of props that need to be included part of the listener
 */
export const useDidMountEffect = (callbackFn: () => any, deps: DependencyList) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      callbackFn();
    } else {
      didMountRef.current = true;
    }
  }, deps);
};
