import React from "react";
import { ASSET_TYPES, VEHICLE_STATUS } from "../../../common/constants";
import { IStatus, ITrailer } from "../../../models/VehicleModel";
import { ReactComponent as Truck } from "../../../assets/images/truck.svg";
import { ReactComponent as TruckBusy } from "../../../assets/images/truck_busy.svg";
import { ReactComponent as Trailer } from "../../../assets/images/trailer.svg";
import { ReactComponent as TruckWithTrailerBusy } from "../../../assets/images/truck_trailer_busy.svg";
import { ReactComponent as TruckWithTrailer } from "../../../assets/images/truck_trailer.svg";
import "./FleetIcon.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
export interface IFleetIconProps {
  type: string;
  trailer?: ITrailer;
  status?: IStatus;
}

const getTruckwithTrailerIcon = (status?: string) => {
  if (status && status === VEHICLE_STATUS.ON_GOING) {
    return <TruckWithTrailerBusy />;
  } else {
    return <TruckWithTrailer />;
  }
};

const getTruckIcon = (status?: string) => {
  if (status && status === VEHICLE_STATUS.ON_GOING) {
    return <TruckBusy />;
  } else {
    return <Truck />;
  }
};

const FleetIcon = (props: IFleetIconProps) => {
  const getFleetIcon = (fleetType: string | undefined, trailer: ITrailer | undefined) => {
    if (fleetType && typeof fleetType !== "undefined") {
      if (trailer && trailer.name) {
        return getTruckwithTrailerIcon(props?.status?.value);
      } else if (fleetType === ASSET_TYPES.TRUCK || fleetType === ASSET_TYPES.MOBILE) {
        return getTruckIcon(props?.status?.value);
      } else if (fleetType === ASSET_TYPES.TRAILER) {
        return <Trailer />;
      } else {
        return "";
      }
    }
    return "";
  };
  const fleetIcon = getFleetIcon(props.type, props.trailer);
  const statusCls = props?.status?.value?.toLowerCase?.() || "";
  const title = props?.status ? i18nInstance.t(`TTM.followup.status.${props?.status?.value}`) : "";
  return fleetIcon ? (
    <span className={`mr-1 fleet ${statusCls}`} title={title}>
      {fleetIcon}
    </span>
  ) : (
    <></>
  );
};
export default FleetIcon;
