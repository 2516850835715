import { useEffect, useRef, useState } from "react";
import { getAppConfig, loadScript } from "../../common/utils";
import { IVehicleData } from "../../models/VehicleModel";
/**
 * Custom hook to initialize pushlet client to listen for notifications for list of vehicles
 * initialize - load the dependency js file which internally handles the XHR calls and expose an window level "PL" property for pushlet subscriptions
 * join - initialize the session with the API endpoint
 * subscribe - comma separated terminal ids (/messages/term/1294)
 * onData - once we have the push from server we will have this method triggered and notified to parent component
 * onError - catch the error and re-try the initialization after a delay
 * @returns
 */
export const usePushletClient = () => {
  const pusletRef = useRef<any>();
  const subscriptionsList = useRef<string>();
  const terminalsList = useRef<string[]>([]);
  const [pushTerminalsNotification, setPushTerminalsNotification] = useState<any>(null);
  let errorTimeout: any = null;
  let notificationTimeout: any = null;
  const MESSAGE_SUBSCRIBE_PREFIX = "/messages/term";
  /**
   * Load the pushclient dependent library and form the subcribers array with comma separated termial ids
   * initiailize notifications and then assign window level method listeners as callback function
   * @param terminalsList - list of terminals (fetched during module load once after successful authz)
   */
  const initPushClient = (terminalsList: IVehicleData[]) => {
    const subscribers: string[] = [];
    try {
      loadScript(
        `${process.env.PUBLIC_URL}/assets/js/ajax-pushlet-client.js?v=${process.env.REACT_APP_VERSION}`,
        "pushlet-js",
      )
        .then(() => {
          pusletRef.current = window.PL;
          terminalsList &&
            terminalsList.length > 0 &&
            terminalsList.map((vehicle: IVehicleData) => {
              subscribers.push(`${MESSAGE_SUBSCRIBE_PREFIX}${vehicle.id}`);
            });
          subscriptionsList.current = subscribers.join(",");
          initialiseNotifications();
          window.onError = handleError;
          window.onData = handleData;
        })
        .catch((e) => {
          console.log(`Error in loading notification lib - ${e}`);
        });
    } catch (error) {
      console.log(`Error in initPushClient - ${error}`);
    }
  };
  /**
   * Method to assign the pushAPI endpoint to library root and the initialize subscription
   */
  function initialiseNotifications() {
    try {
      if (pusletRef.current && subscriptionsList.current !== "") {
        pusletRef.current.webRoot = getAppConfig().pushApi;
        pusletRef.current.setDebug?.(false);
        notificationSubscription();
      }
    } catch (error) {
      console.log(`Error in initialiseNotifications - ${error}`);
    }
  }
  /**
   * Method to initialize the pushlet client, session and subscription to the terminals list assigned
   */
  function notificationSubscription() {
    try {
      if (pusletRef.current) {
        pusletRef.current._init?.();
        pusletRef.current.joinListen?.(null);
        pusletRef.current.subscribe?.(subscriptionsList.current);
      }
    } catch (error) {
      console.log(`Error in notificationSubscription - ${error}`);
    }
  }
  /**
   * Method to trigger notification to parent level with list of notified terminals
   */
  const triggerNotification = () => {
    const teminalsList = [...terminalsList.current];
    terminalsList.current = [];
    setPushTerminalsNotification(teminalsList);
  };
  /**
   * Method to listen for data push event and validate the data with event type and subject
   * Then check for terminal id and push it for notifications list inorder to refresh the view after a delay
   * When we receive frequent updates, we will delay the notification part for 3 seconds and notify the parent with consolidated list at that point of time
   * @param e
   */
  function handleData(e: any) {
    try {
      if (e?.arr?.["p_event"] === "data" && e?.arr?.["p_subject"] !== "") {
        //subject pattern - /messages/term1294
        const terminalId = e?.arr["p_subject"]?.split?.(MESSAGE_SUBSCRIBE_PREFIX)[1] || "";
        if (terminalId) {
          if (!terminalsList.current.includes(terminalId)) {
            terminalsList.current.push(terminalId);
          }
          if (!notificationTimeout) {
            notificationTimeout = setTimeout(() => {
              triggerNotification();
              notificationTimeout = null;
            }, 3000);
          }
        }
      }
    } catch (error) {
      console.log(`Error in handleData - ${error}`);
    }
  }
  /**
   * Method to listen for any errors and initialise subscription
   * @param e
   */
  function handleError(e: any) {
    console.log(`Error in pushlet - ${e}`);
    if (e?.arr?.["p_event"] === "error") {
      errorTimeout = setTimeout(() => {
        pusletRef?.current && pusletRef?.current.leave?.();
        notificationSubscription();
      }, 5000);
    }
  }
  useEffect(() => {
    return () => {
      pusletRef?.current && pusletRef?.current.leave?.();
      terminalsList.current = [];
      errorTimeout && clearTimeout(errorTimeout);
      notificationTimeout && clearTimeout(notificationTimeout);
    };
  }, []);
  return [initPushClient, pushTerminalsNotification];
};
