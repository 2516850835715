import { action, makeObservable, observable } from "mobx";
import {
  defaultToastObj,
  IToast,
} from "../../components/application/molecules/ToastWrapper/ToastWrapper";
import { IRoute, ITask } from "../../models/VehicleDetailsModel";
import { IETAtoLocation, IVehicleData } from "../../models/VehicleModel";

export class VehicleStore {
  selectedVehicle: IVehicleData | null = null;
  selectedRoute: IRoute | null | undefined = null;
  selectedTask: ITask | null = null;
  lastUpdatedTime = 0;
  toastProps = defaultToastObj;
  etaToSelectedLocation: IETAtoLocation[] = [];

  constructor() {
    makeObservable(this, {
      selectedVehicle: observable,
      selectedTask: observable,
      selectedRoute: observable,
      lastUpdatedTime: observable,
      toastProps: observable,
      etaToSelectedLocation: observable,
      setToastProps: action,
      setSelectedVehicle: action,
      setSelectedTask: action,
      setSelectedRoute: action,
      setLastUpdatedTime: action,
      setEtaToLocation: action,
    });
  }
  setSelectedVehicle = (vehicle: IVehicleData | null) => {
    this.selectedVehicle = vehicle;
  };
  setSelectedTask = (task: ITask | null): void => {
    this.selectedTask = task;
  };
  setSelectedRoute = (route: IRoute | null | undefined): void => {
    this.selectedRoute = route;
  };
  setLastUpdatedTime = (time: number): void => {
    this.lastUpdatedTime = time;
  };
  setToastProps = (param: IToast) => {
    this.toastProps = param;
  };
  setEtaToLocation = (eta: IETAtoLocation | null) => {
    if (eta) {
      this.etaToSelectedLocation = [...this.etaToSelectedLocation, eta];
    } else {
      this.etaToSelectedLocation = [];
    }
  };
}
