import React, { createContext, useContext, useMemo } from "react";
import { AppStore } from "../stores/appStore/app.store";

const AppContext = createContext<AppStore | null>(null);
export const useAppStore = () => useContext(AppContext);

export const AppProvider = ({ children }: any) => {
  const appStore = useMemo(() => new AppStore(), []);
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>;
};
export default AppContext;
