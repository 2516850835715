import React from "react";
import TwoColumnTemplate from "../../../../global/template/TwoColumnTemplate/TwoColumnTemplate";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { observer } from "mobx-react-lite";
import { useTraceStore } from "../../../../../contexts/traces.context";

const TracesDesktopContainer = () => {
  const traceStore = useTraceStore();
  return (
    !traceStore?.showError ? (
      <TwoColumnTemplate
        leftPane={{
          component: (
            <div className="d-flex h-100 justify-content-center">
              <Preloader />
            </div>
          ),
          colSpan: 6,
        }}
        rightPane={{
          component: (
            <div className="d-flex h-100 justify-content-center">
              <Preloader />
            </div>
          ),
          colSpan: 6,
        }}
      />
    ) : 
    <div className="d-flex h-100 justify-content-center align-items-center">{i18nInstance.t("TTM.followup.generic.error")}</div>
  );
};

export default observer(TracesDesktopContainer);
