import React from "react";
import Form from "@trimbleinc/modus-react-bootstrap/esm/Form";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import FormGroup from "@trimbleinc/modus-react-bootstrap/esm/FormGroup";
import { IMapLayerStyleOption } from "../MapLayerStyleContainer/MapLayerStyleContainer";
import trafficInfo from "../../../../assets/images/map_layer_traffic_information.svg";
import truckAttributes from "../../../../assets/images/map_layer_truck_attributes.svg";

export interface IMapLayer {
  zoomLevel: number;
  selectedMapLayers: string[];
  handleMapLayerChange: (layer: IMapLayerStyleOption) => void;
}

export const MAPLAYERS = {
  TRAFFIC: "traffic",
  TRUCK: "truck",
};

export const MAP_LAYERS_OPTIONS = [
  {
    img: trafficInfo,
    key: "TTM.followup.map.layer.trafficInformation",
    type: MAPLAYERS.TRAFFIC,
  },
  {
    img: truckAttributes,
    key: "TTM.followup.map.layer.truckAttributes",
    type: MAPLAYERS.TRUCK,
  },
];

const MapLayer = (props: IMapLayer) => {
  const handleCheckBoxDisable = (mapLayerType: IMapLayerStyleOption) => {
    let flag;
    if (mapLayerType.type === MAPLAYERS.TRAFFIC) {
      flag = props.zoomLevel < 9 ? true : false;
    }
    if (mapLayerType.type === MAPLAYERS.TRUCK) {
      flag = props.zoomLevel < 16 ? true : false;
    }
    return flag;
  };

  return (
    <>
      <span className="map-style-layer-title">{i18nInstance.t("TTM.followup.map.mapLayers")}</span>
      <FormGroup className="m-0">
        {MAP_LAYERS_OPTIONS.map((mapLayer: IMapLayerStyleOption) => {
          const isDisabled = handleCheckBoxDisable(mapLayer);
          return (
            <div
              className={`option-container ${isDisabled ? "disabled" : null}`}
              key={mapLayer.key}
              title={i18nInstance.t(mapLayer.key)}
              onClick={() => props.handleMapLayerChange(mapLayer)}
            >
              <Form.Check
                type="checkbox"
                checked={props.selectedMapLayers.includes(mapLayer.type)}
                id={mapLayer.key}
                disabled={isDisabled}
                readOnly
              />
              <img src={mapLayer.img} className={isDisabled ? "option-icon-disabled" : ""} />
              <span className={`option-label ${isDisabled ? "disabled" : null}`}>
                {i18nInstance.t(mapLayer.key)}
              </span>
            </div>
          );
        })}
      </FormGroup>
    </>
  );
};

export default withAuth(MapLayer);
